import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Trans, useTranslation } from 'react-i18next';

import { useCountry } from '../../../contexts/LocaleContext';
import isScreenWidthUp from '../../../utils/isScreenWidthUp';
import useFormatNumber from '../../../utils/useFormatNumber';
import useWindowSize from '../../../utils/useWindowSize';
import {
	Col,
	Container,
	Row,
	Section,
} from '../../layout';
import { ExternalLink } from '../../ui';

import styles from './FeesChart.module.scss';

const dataFondee = {
	CZ: [
		100000, 106100, 112500, 119300, 126600, 134300, 142400, 151100, 160300,
		170000, 180300, 191300, 202900, 215200, 228300, 242100,
	],
	PL: [
		10000, 10607, 11251, 11935, 12659, 13428, 14244, 15109, 16026, 16999,
		18032, 19127, 20288, 21520, 22827, 24213,
	],
	SK: [
		5000, 5304, 5626, 5967, 6330, 6714, 7122, 7554, 8013, 8500, 9016, 9563,
		10144, 10760, 11414, 12107,
	],
};

const dataManaged = {
	CZ: [
		100000, 104400, 109000, 113700, 118700, 123900, 129400, 135000, 141000,
		147200, 153600, 160300, 167400, 174700, 182400, 190400,
	],
	PL: [
		10000, 10439, 10896, 11374, 11873, 12394, 12937, 13505, 14097, 14715,
		15361, 16034, 16738, 17472, 18238, 19038,
	],
	SK: [
		5000, 5219, 5448, 5687, 5937, 6197, 6469, 6752, 7049, 7358, 7680, 8017,
		8369, 8736, 9119, 9519,
	],
};

const dataMin = { CZ: 100000, PL: 10000, SK: 5000 };
const dataMax = { CZ: 240000, PL: 24000, SK: 12000 };

const chartOptions = (formatTooltip, formatX, formatY, min, max) => ({
	chart: {
		toolbar: {
			show: false,
		},
		zoom: {
			enabled: false,
		},
	},
	colors: ['#e94549', '#f39200'],
	dataLabels: {
		enabled: false,
	},
	fontFamily: 'Rajdhani, sans-serif',
	legend: {
		horizontalAlign: 'left',
		fontSize: '17px',
		formatter(seriesName) {
			return seriesName;
		},
		itemMargin: {
			horizontal: 15,
			vertical: 8,
		},
	},
	stroke: {
		curve: 'straight',
		width: 3,
	},
	tooltip: {
		shared: false,
		custom({ series, seriesIndex, dataPointIndex }) {
			return (
				`<div class="${styles.tooltip} ${seriesIndex === 0 ? styles.tooltipRed : styles.tooltipOrange}">
					<b>
						${formatTooltip(series[seriesIndex][dataPointIndex])}
					</b>
				</div>`
			);
		},
		x: {
			show: false,
		},
		marker: {
			show: false,
		},
	},
	xaxis: {
		type: 'numeric',
		tickAmount: 5,
		min: 0,
		max: 15,
		labels: {
			style: {
				fontSize: '14px',
			},
			formatter: formatX,
		},
		tooltip: {
			enabled: false,
		},
	},
	yaxis: {
		tickAmount: 7,
		min,
		max,
		labels: {
			style: {
				fontSize: '14px',
			},
			formatter: formatY,
		},
	},
});

export default function FeesChart({
	chartAnchor,
}) {
	const [t] = useTranslation();
	const country = useCountry();
	const formatNumber = useFormatNumber({ fractionDigits: 0 });
	const windowSize = useWindowSize();

	const formatX = (value) => t('feesChart.chart.years', { count: value });
	const formatY = (value) => `${formatNumber(value / 1000)}${t('feesChart.chart.currencyThousandsSuffix')}`;

	const min = dataMin[country] ?? dataMin.CZ;
	const max = dataMax[country] ?? dataMax.CZ;

	const formatTooltip = min < 10000 ? (value) => `${formatNumber(value)}${t('feesChart.chart.currencySuffix')}` : formatY;

	const series = [{
		name: 'Fondee',
		data: (dataFondee[country] ?? dataFondee.CZ).map((y, x) => ({ x, y })),
	}, {
		name: t('feesChart.chart.curveLabel'),
		data: (dataManaged[country] ?? dataManaged.CZ).map((y, x) => ({ x, y })),
	}];

	return (
		<Section id={chartAnchor} bordered>
			<Container>
				<h2 className={styles.title}>
					<Trans i18nKey="feesChart.title">
						<br />
					</Trans>
				</h2>
				<Row center middle>
					<Col lg={6}>
						<div className={styles.wrap}>
							<Chart
								height={500}
								options={chartOptions(formatTooltip, formatX, formatY, min, max)}
								series={series}
								type="line"
							/>
						</div>
					</Col>
					{isScreenWidthUp(windowSize.width, 'lg') && (
						<Col lg={1} />
					)}
					<Col lg={3}>
						<h3 className={styles.subtitle}>
							{t('feesChart.subtitle')}
						</h3>
						<Trans i18nKey="feesChart.info">
							<p className={styles.paragraph}>
								<strong />
								<ExternalLink
									className={styles.link}
									to={t('documentUrls.feesIllustratedUrl')}
									blank
								/>
							</p>
						</Trans>
					</Col>
				</Row>
			</Container>
		</Section>
	);
}

FeesChart.propTypes = {
	chartAnchor: PropTypes.string.isRequired,
};
