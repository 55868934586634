import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useUserContext } from '../../../contexts/UserContext';
import { Section, Container } from '../../layout';
import PortfolioCreate, { PortfolioCreateDisabled } from '../PortfolioCreate';

import styles from './NoActivePortfolio.module.scss';

export default function NoActivePortfolio({
	accountUrl,
	childOnboardingUrl,
	conditionsUrl,
	currencies,
	parentOnboardingUrl,
	portfolios,
	reloadDashboard,
	reloadPortfolios,
	setScrollDashboard,
	signNewAgreementUrl,
}) {
	const [t] = useTranslation();
	const [user] = useUserContext();

	return (
		<Section>
			<Container>
				<div className={styles.root}>
					<h2 className={styles.title}>
						{t('portfolio.overview.noActivePortfolio')}
					</h2>
					{!user.child && (user.client_zone_locked ? (
						<PortfolioCreateDisabled noActivePortfolio />
					) : (
						<PortfolioCreate
							accountUrl={accountUrl}
							childOnboardingUrl={childOnboardingUrl}
							conditionsUrl={conditionsUrl}
							currencies={currencies}
							parentOnboardingUrl={parentOnboardingUrl}
							portfolios={portfolios}
							reloadDashboard={reloadDashboard}
							reloadPortfolios={reloadPortfolios}
							setScrollDashboard={setScrollDashboard}
							signNewAgreementUrl={signNewAgreementUrl}
							noActivePortfolio
						/>
					))}
				</div>
			</Container>
		</Section>
	);
}

NoActivePortfolio.propTypes = {
	accountUrl: PropTypes.string.isRequired,
	childOnboardingUrl: PropTypes.string.isRequired,
	conditionsUrl: PropTypes.string.isRequired,
	currencies: PropTypes.arrayOf(PropTypes.shape({
		code: PropTypes.string.isRequired,
		codeShort: PropTypes.string.isRequired,
		codeShortBefore: PropTypes.bool.isRequired,
	}).isRequired),
	parentOnboardingUrl: PropTypes.string.isRequired,
	portfolios: PropTypes.shape({
		active: PropTypes.arrayOf(
			PropTypes.shape({
				categoryName: PropTypes.string.isRequired,
				child: PropTypes.shape({
					id: PropTypes.number.isRequired,
				}),
			}).isRequired,
		).isRequired,
	}),
	reloadDashboard: PropTypes.func,
	reloadPortfolios: PropTypes.func,
	setScrollDashboard: PropTypes.func,
	signNewAgreementUrl: PropTypes.string.isRequired,
};

NoActivePortfolio.defaultProps = {
	currencies: null,
	portfolios: null,
	reloadDashboard: null,
	reloadPortfolios: null,
	setScrollDashboard: null,
};
