import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ProductSelect } from '../../../components/common';
import { Alert, Button, Toggle } from '../../../components/ui';
import { saveProfileProduct } from '../../../utils/api';
import useRequestAuth from '../../../utils/useRequestAuth';

import styles from './OnboardingProduct.module.scss';

export default function OnboardingProduct({
	setCheckOnboarding,
	setChildProduct,
}) {
	const [t] = useTranslation();
	const [category, setCategory] = useState('standard');
	const [esg, setEsg] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);
	const saveProfileProductAuth = useRequestAuth(saveProfileProduct);
	const isChild = category === 'child';

	const handleSubmit = async () => {
		if (isChild) {
			setChildProduct(esg ? 'esg' : 'classic');
			return;
		}

		setSubmitting(true);
		setError(false);
		try {
			await saveProfileProductAuth(esg, category);
		} catch (e) {
			setError(true);
			return;
		} finally {
			setSubmitting(false);
		}

		setCheckOnboarding(true);
	};

	return (
		<div className={styles.root}>
			{error && (
				<Alert type="danger">
					{t('forms.error')}
				</Alert>
			)}
			<div className={styles.section}>
				<ProductSelect
					category={category}
					onboarding
					setCategory={setCategory}
				/>
				<Toggle
					labelFirst={t('investorProfilePreview.classicPortfolios')}
					labelSecond={t('investorProfilePreview.esgPortfolios')}
					setToggle={setEsg}
					toggle={esg}
					tooltipFirst={t('investorProfileChoice.tooltip.classic')}
					tooltipSecond={t('investorProfileChoice.tooltip.esg')}
				/>
				{esg ? (
					<p className={styles.text}>
						{t('investorProfileChoice.category.esg')}
					</p>
				) : (
					<p className={styles.text}>
						{t('investorProfileChoice.category.classic')}
					</p>
				)}
			</div>

			<div className={styles.section}>
				{isChild && (
					<p className={styles.text}>
						{t('onboarding.steps.product.childInfo')}
					</p>
				)}
				<Button
					disabled={submitting}
					label={t('onboarding.steps.personalData.form.submit')}
					onClick={handleSubmit}
				/>
			</div>
		</div>
	);
}

OnboardingProduct.propTypes = {
	setCheckOnboarding: PropTypes.func.isRequired,
	setChildProduct: PropTypes.func.isRequired,
};
