/* global window */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useChangeLanguage, useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { usePage } from '../../../contexts/PageContext';
import { useUserContext } from '../../../contexts/UserContext';
import { pageOrRootUrl } from '../../../utils/routes';
import { useCountrySwitchUrl, useLanguageSwitchUrl } from '../../../utils/useSwitchUrl';
import { Loader } from '../../ui';

import styles from './UserCheck.module.scss';

export const clientZonePages = {
	account: { legacyClient: true },
	clientCheck: { clientZoneLocked: true },
	dashboard: { clientZoneLocked: true },
	faq: { prospect: true },
	history: true,
	offers: { prospect: true },
	onboarding: { prospect: true },
	onboardingChild: true,
	onboardingParent: true,
	portfolio: true,
	referrals: { prospect: true },
	taxes: true,
	signAgreement: { legacyClient: true, withClientZoneLocked: true },
	signNewAgreement: { contractMissing: true },
};

export default function UserCheck({
	baseUrls,
	children,
}) {
	const country = useCountry();
	const language = useLanguage();
	const changeLanguage = useChangeLanguage();
	const page = usePage();
	const [user, loading] = useUserContext();
	const countrySwitchUrl = useCountrySwitchUrl(baseUrls);
	const languageSwitchUrl = useLanguageSwitchUrl();
	const location = useLocation();
	const navigate = useNavigate();

	const clientZonePage = page !== null ? (clientZonePages[page] ?? null) : null;
	const countrySwitch = !!clientZonePage && user !== null && country !== user.country_id
		? countrySwitchUrl(user.country_id)
		: null;
	const languageSwitch = user !== null && language !== user.language_id
		? languageSwitchUrl(user.language_id)
		: null;

	useEffect(() => {
		if (countrySwitch !== null) {
			window.location.href = countrySwitch;
		} else if (languageSwitch !== null && changeLanguage(user?.language_id, false)) {
			navigate(languageSwitch);
		}
	}, [countrySwitch, languageSwitch, navigate, user?.language_id]);

	if (!clientZonePage) {
		return children;
	}

	if (loading || countrySwitch !== null) {
		return (
			<div className={styles.root}>
				<Loader />
			</div>
		);
	}

	const navigateUrl = (pageTo) => pageOrRootUrl(country, language, pageTo);
	const redirectIf = (redirect, pageTo) => (redirect ? <Navigate to={navigateUrl(pageTo)} /> : children);

	if (user === null) {
		return <Navigate to={navigateUrl('login')} state={{ from: location }} />;
	}

	if (!user.email_verified) {
		return redirectIf(!clientZonePage.prospect, 'emailVerification');
	}

	if (!user.active_client) {
		return redirectIf(!clientZonePage.prospect, 'onboarding');
	}

	if (user.legacy_client) {
		return redirectIf(
			!clientZonePage.legacyClient || (user.client_zone_locked && !clientZonePage.withClientZoneLocked),
			'signAgreement',
		);
	}

	if (user.contract_missing) {
		return redirectIf(!clientZonePage.contractMissing, 'signNewAgreement');
	}

	return redirectIf(user.client_zone_locked && !clientZonePage.clientZoneLocked, 'clientCheck');
}

UserCheck.propTypes = {
	baseUrls: PropTypes.objectOf(PropTypes.string.isRequired),
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

UserCheck.defaultProps = {
	baseUrls: null,
	children: null,
};
