import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
	FooterCta,
	ProductBenefits,
	ProductFees,
	ProductHero,
	ProductProfileDetail,
	ProductTerms,
} from '../../../components/common';
import {
	Container,
	Section,
} from '../../../components/layout';
import ChildCard from '../Cards/ChildCard';
import ClassicCard from '../Cards/ClassicCard';
import EsgCard from '../Cards/EsgCard';
import PensionCard from '../Cards/PensionCard';

import styles from '../Products.module.scss';

const product = 'student';

export default function StudentProduct({
	blogPostCompoundUrl,
	blogPostEtfUrl,
	blogPostEsgUrl,
	blogPostEsgComparisonUrl,
	blogPostEsgVolatilityUrl,
	blogPostFinancesUrl,
	blogPostInflationUrl,
	childProductUrl,
	classicProductUrl,
	esgProductUrl,
	howItWorksUrl,
	pensionProductUrl,
	registrationUrl,
}) {
	const [t] = useTranslation();

	return (
		<>
			<Section condensed>
				<Container>
					<ProductHero
						product={product}
						registrationUrl={registrationUrl}
					/>
				</Container>
			</Section>
			<Section bordered condensed>
				<Container>
					<ProductProfileDetail
						product={product}
						howItWorksUrl={howItWorksUrl}
					/>
					<ProductFees
						howItWorksUrl={howItWorksUrl}
						product={product}
					/>
				</Container>
			</Section>
			<Section bordered>
				<Container>
					<ProductTerms
						blogPostEtfUrl={blogPostEtfUrl}
						blogPostFinancesUrl={blogPostFinancesUrl}
						esgProductUrl={esgProductUrl}
						product={product}
						registerUrl={registrationUrl}
					/>
				</Container>
			</Section>
			<Section>
				<Container>
					<ProductBenefits
						blogPostCompoundUrl={blogPostCompoundUrl}
						blogPostEsgUrl={blogPostEsgUrl}
						blogPostEsgComparisonUrl={blogPostEsgComparisonUrl}
						blogPostEsgVolatilityUrl={blogPostEsgVolatilityUrl}
						blogPostInflationUrl={blogPostInflationUrl}
						product={product}
					/>
					<div className={styles.title}>
						{t('products.list.title')}
					</div>
					<div className={styles.wrap}>
						<EsgCard esgProductUrl={esgProductUrl} />
						<ClassicCard classicProductUrl={classicProductUrl} />
						<PensionCard pensionProductUrl={pensionProductUrl} />
						<ChildCard childProductUrl={childProductUrl} />
					</div>
				</Container>
			</Section>
			<FooterCta registrationUrl={registrationUrl} />
		</>
	);
}

StudentProduct.propTypes = {
	blogPostCompoundUrl: PropTypes.string,
	blogPostEtfUrl: PropTypes.string,
	blogPostEsgUrl: PropTypes.string,
	blogPostEsgComparisonUrl: PropTypes.string,
	blogPostEsgVolatilityUrl: PropTypes.string,
	blogPostFinancesUrl: PropTypes.string,
	blogPostInflationUrl: PropTypes.string,
	childProductUrl: PropTypes.string.isRequired,
	classicProductUrl: PropTypes.string.isRequired,
	esgProductUrl: PropTypes.string.isRequired,
	howItWorksUrl: PropTypes.string.isRequired,
	pensionProductUrl: PropTypes.string.isRequired,
	registrationUrl: PropTypes.string.isRequired,
};

StudentProduct.defaultProps = {
	blogPostCompoundUrl: null,
	blogPostEtfUrl: null,
	blogPostEsgUrl: null,
	blogPostEsgComparisonUrl: null,
	blogPostEsgVolatilityUrl: null,
	blogPostFinancesUrl: null,
	blogPostInflationUrl: null,
};
