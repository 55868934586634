function getUserAge(birthDate) {
	if (!birthDate) {
		return null;
	}

	return new Date().getFullYear() - new Date(birthDate).getFullYear()
		- (new Date() < new Date(birthDate).setFullYear(new Date().getFullYear()) ? 1 : 0);
}

export default getUserAge;
