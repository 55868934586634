import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import { Container, Section } from '../../components/layout';
import { EmailLink, Loader } from '../../components/ui';
import { useUserContext, useUserReload } from '../../contexts/UserContext';
import { fetchChildrenList } from '../../utils/api';
import useFetchWithReload from '../../utils/useFetchWithReload';
import { toNumber } from '../../utils/number';
import AgreementAccountSelect from './AgreementAccountSelect';
import SignNewAgreementSteps from './SignNewAgreementSteps';

import styles from './SignNewAgreement.module.scss';

const useFetchChildrenList = useFetchWithReload(fetchChildrenList);

const findChild = (childIdParam, childrenList) => {
	const childId = childIdParam !== null ? toNumber(childIdParam) || null : null;
	return childId !== null ? childrenList.find(({ id }) => id === childId) ?? null : null;
};

const validateContractSelect = (categoryParam, childIdParam, user, childrenList) => {
	const categories = user.documents?.contractMissing ?? {};
	const contractCategories = Object.keys(categories).filter((key) => !!categories[key]);
	const category = categoryParam !== null && contractCategories.includes(categoryParam) ? categoryParam : null;

	const contractChildren = childrenList.filter((child) => !!child.documents?.contractMissing);
	const childId = findChild(childIdParam, contractChildren)?.id ?? null;

	return {
		...(
			(category !== null ? { category } : null)
			?? (childId !== null ? { childId } : null)
			?? ((contractCategories[0] ?? null) !== null ? { category: contractCategories[0] } : null)
			?? ((contractChildren[0]?.id ?? null) !== null ? { childId: contractChildren[0].id } : null)
		),
		contractCategories,
		contractChildren,
	};
};

const validateCreatePortfolio = (categoryParam, childIdParam, user, childrenList) => {
	const categoryValid = categoryParam !== null ? user.documents?.contractRequired?.[categoryParam] ?? null : null;
	const category = categoryValid ? categoryParam : null;

	const child = findChild(childIdParam, childrenList);
	const childValid = child?.documents?.contractRequired ?? null;
	const childId = childValid ? child?.id ?? null : null;

	if (category === null && childId === null) {
		return {
			redirectState: {
				createPortfolio: true,
				category: (categoryValid !== null ? categoryParam : null) ?? (childValid !== null ? 'child' : null),
			},
		};
	}

	return category !== null ? { category } : { childId };
};

const updateCategory = (newCategory) => (searchParams) => {
	const newSearchParams = new URLSearchParams(searchParams);
	newSearchParams.set('category', newCategory);
	newSearchParams.delete('childId');
	return newSearchParams;
};

const updateChildId = (newChildId) => (searchParams) => {
	const newSearchParams = new URLSearchParams(searchParams);
	newSearchParams.delete('category');
	newSearchParams.set('childId', newChildId);
	return newSearchParams;
};

export default function SignNewAgreement({
	dashboardUrl,
}) {
	const [t] = useTranslation();
	const { state } = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const [user] = useUserContext();
	const reloadUser = useUserReload();
	const [childrenList, , , , reloadChildren] = useFetchChildrenList(null, !!user?.child);

	const setCheckOnboarding = useCallback((check) => {
		if (check) {
			reloadUser();
			reloadChildren();
		}
	}, [reloadUser, reloadChildren]);

	const loading = user === null || childrenList === null;
	const createPortfolio = state?.createPortfolio ?? false;
	const categoryParam = searchParams.get('category') ?? null;
	const childIdParam = searchParams.get('childId') ?? null;
	const validate = createPortfolio ? validateCreatePortfolio : validateContractSelect;

	const {
		category = null,
		childId = null,
		contractCategories = [],
		contractChildren = [],
		redirectState = null,
	} = !loading ? validate(categoryParam, childIdParam, user, childrenList) : {};
	const redirect = !loading && category === null && childId === null;
	const changeParameters = !loading && !redirect
		&& (categoryParam !== category || childIdParam !== (childId !== null ? String(childId) : null));

	useEffect(() => {
		if (!changeParameters) {
			return;
		}

		if (category !== null) {
			setSearchParams(updateCategory(category));
		} else {
			setSearchParams(updateChildId(childId));
		}
	}, [setSearchParams, category, childId, changeParameters]);

	if (redirect) {
		return <Navigate state={redirectState} to={dashboardUrl} />;
	}

	if (loading || changeParameters) {
		return <Loader fullPage />;
	}

	return (
		<>
			{!createPortfolio && (
				<AgreementAccountSelect
					categories={contractCategories}
					category={category}
					childId={childId}
					childrenList={contractChildren}
					selectCategory={(newCategory) => { setSearchParams(updateCategory(newCategory)); }}
					selectChild={(newChildId) => { setSearchParams(updateChildId(newChildId)); }}
				/>
			)}
			<Section id="top">
				<Container>
					<h1 className={styles.title}>
						{t(childId !== null ? 'signNewAgreement.child.title' : 'signNewAgreement.title')}
					</h1>
					{childId !== null ? (
						<p className={styles.text}>
							{t('signNewAgreement.child.description')}
						</p>
					) : (
						<p className={styles.text}>
							{t(`signNewAgreement.text.${category}`, {
								defaultValue: t('signNewAgreement.text.standard'),
								fallbackLng: [],
							})}
						</p>
					)}
					<p className={styles.textSmaller}>
						<Trans i18nKey="signNewAgreement.info">
							<EmailLink />
						</Trans>
					</p>
					<SignNewAgreementSteps
						category={category}
						childId={childId}
						setCheckOnboarding={setCheckOnboarding}
					/>
				</Container>
			</Section>
		</>
	);
}

SignNewAgreement.propTypes = {
	dashboardUrl: PropTypes.string.isRequired,
};
