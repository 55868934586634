import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { ExternalLink } from '../../ui';

import styles from './TransparentFee.module.scss';

export default function TransparentFee({
	product,
}) {
	const [t] = useTranslation();

	return (
		<div className={styles.transparentFee}>
			<p className={styles.title}>
				{t('feesProducts.transparent.title')}
			</p>
			<div className={styles.transparentFeeInner}>
				<div className={styles.transparentFeeRow}>
					<span className={styles.transparentFeeAmount}>
						{t(`feesProducts.products.${product}.transparent.fondee`)}
					</span>
					<p className={styles.transparentFeeText}>
						{t('feesProducts.transparent.fondee')}
					</p>
				</div>
				<div className={styles.transparentFeeRow}>
					<span className={styles.icon}>
						+
					</span>
				</div>
				<div className={styles.transparentFeeRow}>
					<span className={styles.transparentFeeAmount}>
						{t(`feesProducts.products.${product}.transparent.vat`)}
					</span>
					<p className={styles.transparentFeeText}>
						{t('feesProducts.transparent.vat')}
					</p>
				</div>
				<div className={styles.transparentFeeRow}>
					<span className={styles.icon}>
						=
					</span>
				</div>
				<div className={styles.transparentFeeRow}>
					<span className={styles.feeAmount}>
						<strong>
							{t(`feesProducts.products.${product}.transparent.total`)}
						</strong>
					</span>
					<p className={styles.transparentFeeText}>
						{t('feesProducts.transparent.total')}
					</p>
				</div>
			</div>
			<div className={styles.description}>
				<Trans i18nKey={`feesProducts.products.${product}.transparent.description`}>
					<p className={styles.descriptionTitle}>
						<span className={styles.highlight} />
					</p>
					<p className={styles.descriptionText}>
						<ExternalLink
							to={t('documentUrls.priceList')}
							blank
						/>
					</p>
				</Trans>
			</div>
		</div>
	);
}

TransparentFee.propTypes = {
	product: PropTypes.string.isRequired,
};
