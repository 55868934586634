import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useCountry } from '../../../contexts/LocaleContext';
import { useUserContext, useUserReload } from '../../../contexts/UserContext';
import formatAccountNumber from '../../../utils/formatAccountNumber';
import {
	Button,
	EmailLink,
	Modal,
} from '../../ui';
import OnboardingBankAccount from '../OnboardingBankAccount';
import OnboardingFeedback from '../OnboardingFeedback';
import ProgressIcon from '../ProgressIcon';
import TopUp from '../TopUp';

import styles from './OnboardingFinal.module.scss';

export default function OnboardingFinal({
	childId,
	documentsUrl,
	portfolioId,
}) {
	const [isModalOpen, setModalOpen] = useState(false);
	const [t] = useTranslation();
	const country = useCountry();
	const [user, loading] = useUserContext();
	const reloadUser = useUserReload();

	if (loading) return null;

	const userAccounts = Object.values(user?.bank_accounts ?? {});
	const account = formatAccountNumber(userAccounts[0], country);

	return (
		<>
			<div className={styles.root}>
				<div className={styles.block}>
					<div className={styles.titleWrap}>
						<h2 className={styles.title}>{t('onboarding.final.payment.title')}</h2>
						<div className={styles.status}>
							<ProgressIcon />
						</div>
					</div>
					<p className={styles.text}>{t('onboarding.final.payment.text')}</p>
				</div>

				{user.student_status_request_active && (
					<>
						<hr className={styles.line} />
						<div className={styles.block}>
							<div className={styles.titleWrap}>
								<h2 className={styles.title}>{t('onboarding.final.studentConfirmation.title')}</h2>
								<div className={styles.status}>
									<ProgressIcon />
								</div>
							</div>
							<p className={styles.text}>
								<Trans i18nKey="onboarding.final.studentConfirmation.text">
									<EmailLink />
								</Trans>
							</p>
						</div>
					</>
				)}

				<hr className={styles.line} />
				<div className={styles.block}>
					<h2 className={styles.title}>{t('onboarding.final.feedback.title')}</h2>
					<OnboardingFeedback
						childId={childId}
					/>
				</div>
				<hr className={styles.line} />
				<div className={styles.block}>
					<h2 className={styles.title}>{t('onboarding.final.profile.title')}</h2>
					<p className={styles.text}>{t('onboarding.final.profile.text')}</p>
				</div>
				<hr className={styles.line} />
				<div className={styles.block}>
					<h2 className={styles.title}>{t('onboarding.final.topUp.title')}</h2>
					<p className={styles.text}>
						<Trans i18nKey="onboarding.final.topUp.text">
							<a
								href="#topup"
								onClick={(e) => {
									e.preventDefault();
									setModalOpen(!isModalOpen);
								}}
							>
								{null}
							</a>
							{{ account }}
						</Trans>
					</p>
					{userAccounts.length < 3 && (
						<>
							<p className={styles.text}>{t('onboarding.final.topUp.newAccount')}</p>
							<OnboardingBankAccount
								childId={childId}
								reloadUser={reloadUser}
							/>
						</>
					)}
				</div>
				<hr className={styles.line} />
				<div className={styles.block}>
					<h2 className={styles.title}>{t('onboarding.final.agreement.title')}</h2>
					<p className={styles.text}>
						<Trans i18nKey="onboarding.final.agreement.text">
							<Link to={documentsUrl} />
						</Trans>
					</p>
				</div>
				<hr className={styles.line} />
				<div className={styles.block}>
					<h2 className={styles.title}>{t('onboarding.final.contact.title')}</h2>
					<p className={styles.text}>
						<Trans i18nKey="onboarding.final.contact.text">
							<EmailLink />
						</Trans>
					</p>
				</div>
			</div>
			<Modal
				isVisible={isModalOpen}
				onClose={() => setModalOpen(false)}
				title={t('topUp.modal.title')}
				size="large"
			>
				<TopUp
					childId={childId}
					defaultPortfolioId={portfolioId}
				/>
				<div className={styles.modalClose}>
					<Button label={t('topUp.modal.close')} onClick={() => setModalOpen(false)} />
				</div>
			</Modal>
		</>
	);
}

OnboardingFinal.propTypes = {
	childId: PropTypes.number,
	documentsUrl: PropTypes.string.isRequired,
	portfolioId: PropTypes.number,
};

OnboardingFinal.defaultProps = {
	childId: null,
	portfolioId: null,
};
