import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ButtonLink } from '../../../components/ui';

import styles from '../Products.module.scss';

export default function StudentCard({
	studentProductUrl,
}) {
	const [t] = useTranslation();

	return (
		<div className={styles.content}>
			<div className={styles.icon}>
				<svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} viewBox="0 0 130.24 128.03">
					<path fill="#60b5e6" d="M126.65 64.02a62.11 62.11 0 1 1-124.23 0 62.11 62.11 0 0 1 124.23 0" />
					<path fill="#fff" d="M113.25 64.02a48.36 48.36 0 1 1-96.74 0 48.36 48.36 0 0 1 96.75 0" />
					<path
						fill="#1e0e09"
						d="M90.27 61.18v17.2c0 5.22-11.42 9.45-25.5 9.45-7.65 0-14.51-1.25-19.18-3.23V63.92l13.23 5.26 5.67 2.26 5.77-2.3 20.01-7.96Z"
					/>
					<path
						fill="#3c4347"
						d="m107.93 54.15-17.66 7.03-20.01 7.96-5.77 2.3-5.67-2.26-13.23-5.26v-.1l7.5-2.9 14.34-5.55-7.81-2.59-22.11 7.92-16.47-6.55 43.45-14.34 43.44 14.34z"
					/>
					<path fill="#e84917" d="m67.43 55.37-14.34 5.55-7.5 2.9v32.84l-8.44-4.48.01-31.35.35-.13 22.11-7.92 7.81 2.59z" />
				</svg>
			</div>
			<p className={styles.subTitle}>
				{t('products.list.student.title')}
			</p>
			<p className={styles.text}>
				{t('products.list.student.text')}
			</p>
			<ButtonLink
				label={t('products.list.student.button')}
				to={studentProductUrl}
				outline
			/>
		</div>
	);
}

StudentCard.propTypes = {
	studentProductUrl: PropTypes.string.isRequired,
};
