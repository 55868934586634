export default {
	account: 'muj-ucet',
	blog: 'blog',
	childProduct: 'produkty/ucet-pro-dite',
	classicProduct: 'produkty/klasicky-ucet',
	clientCheck: 'kontrola',
	contact: 'zeptejte-se',
	cookies: 'cookies',
	dashboard: 'prehled',
	documents: 'dokumenty',
	emailAlias: 'propojeni-uctu',
	emailVerification: 'registrace/overeni-emailu',
	esgProduct: 'produkty/esg-ucet',
	faq: 'caste-dotazy',
	fees: 'poplatky',
	forgotPassword: 'prihlaseni/obnova-hesla',
	history: 'historie',
	homepage: '',
	howItWorks: 'jak-to-funguje',
	login: 'prihlaseni',
	newspaper: 'zitradnes',
	offers: 'neco-navic',
	onboarding: 'dokonceni-registrace',
	onboardingChild: 'registrace-ditete',
	onboardingParent: 'registrace-rodice',
	ourApproach: 'nas-pristup',
	pensionProduct: 'produkty/penzijni-investicni-ucet',
	portfolio: 'portfolio',
	privacyPolicy: 'ochrana-osobnich-udaju',
	referrals: 'doporuceni',
	registration: 'registrace',
	resetPassword: 'prihlaseni/zmena-hesla',
	signAgreement: 'podpis-smlouvy',
	signNewAgreement: 'podpis-nove-smlouvy',
	studentProduct: 'produkty/studentsky-ucet',
	unsubscribe: 'unsubscribe',
};

export const subpages = {
	blog: {
		compound: 'slozene-uroceni-seznamte-se-a-stane-se-vasi-zivotni-laskou',
		esg: 'co-je-esg-investovani-a-proc-investovat-udrzitelne',
		esgComparison: 'kompletni-srovnani-klasickych-a-esg-investicnich-portfolii-fondee#a-co-zhodnoceni-srovnali-jsme-vyvoj-obou-typu-portfolii',
		esgRegulation: 'co-je-to-esg-regulace-sfdr-eu-taxonomie-pai-chytate-se',
		esgVolatility: '5-duvodu-proc-investovat-udrzitelne#esg-vas-chrani-pred-investicnimi-riziky-odpovedne-investice-klesaji-o-20-mene',
		etf: 'co-to-jsou-etf-proc-by-vas-mela-zajimat-a-co-o-nich-musite-vedet',
		finances: 'financni-rezerva-proc-ji-mit-jak-vysokou-a-kam-s-ni',
		inflation: 'co-to-je-inflace-proc-kvuli-ni-chudneme-a-jak-se-ji-branit',
		pension: 'sporeni-na-duchod',
		longTerm: 'dlouhodoby-investicni-produkt',
		taxes: 'jak-na-zdaneni-investic-do-etf-akcii-a-dalsich-cennych-papiru',
	},
};
