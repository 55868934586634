import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from '../../ui';

import buttonStyles from '../../ui/Button/Button.module.scss';
import styles from './PortfolioCreate.module.scss';

export default function PortfolioCreateDisabled({
	noActivePortfolio,
}) {
	const [t] = useTranslation();

	return (
		<div className={styles.root}>
			{noActivePortfolio ? (
				<div className={buttonStyles.tooltipWrap}>
					<button
						className={styles.button}
						disabled
						type="button"
					>
						<svg viewBox="0 0 26 24" width="20" height="22" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
							<path d="M14.695 9.805V1.5h-3.39v8.305H3v3.39h8.305V21.5h3.39v-8.305H23v-3.39h-8.305z" fill="currentColor" />
						</svg>
						{t('portfolio.new.create')}
					</button>
					<div className={buttonStyles.tooltip}>
						<p>
							{t('clientCheck.disabledTooltip')}
						</p>
					</div>
				</div>
			) : (
				<Button
					disabled
					label={t('portfolio.new.create')}
					tooltip={t('clientCheck.disabledTooltip')}
				/>
			)}
		</div>
	);
}

PortfolioCreateDisabled.propTypes = {
	noActivePortfolio: PropTypes.bool,
};

PortfolioCreateDisabled.defaultProps = {
	noActivePortfolio: false,
};
