import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

import styles from './AgreementAccountSelect.module.scss';

const responsiveSettings = [
	{
		breakpoint: 576,
		settings: {
			slidesToShow: 1,
			centerMode: true,
		},
	}, {
		breakpoint: 780,
		settings: {
			slidesToShow: 2,
		},
	}, {
		breakpoint: 1080,
		settings: {
			slidesToShow: 3,
		},
	}, {
		breakpoint: 1200,
		settings: {
			slidesToShow: 4,
		},
	}, {
		breakpoint: 1350,
		settings: {
			slidesToShow: 4,
		},
	}, {
		breakpoint: 1500,
		settings: {
			slidesToShow: 5,
		},
	},
];

const PrevArrow = (props) => {
	const { onClick, className } = props;
	const [t] = useTranslation();
	const isDisabled = className && className.includes('slick-disabled');

	return (
		<button
			className={styles.sliderArrow}
			disabled={isDisabled}
			type="button"
			onClick={onClick}
		>
			<svg xmlns="http://www.w3.org/2000/svg" width={22} height={24} fill="none">
				<path
					fill="#47202A"
					d="m12.1 3.136-1.043-1.04a1.125 1.125 0 0 0-1.593 0L.33 11.202a1.118 1.118 0 0 0 0 1.59l9.135 9.112c.442.44 1.156.44 1.593 0l1.044-1.04a1.124 1.124 0 0 0-.02-1.609l-5.662-5.38h13.506c.625 0 1.128-.502 1.128-1.125v-1.5c0-.623-.503-1.125-1.128-1.125H6.419l5.663-5.381c.46-.436.47-1.163.019-1.608Z"
				/>
			</svg>
			<p className={styles.text}>
				{t('clientCheck.profileSelect.prev')}
			</p>
		</button>
	);
};

PrevArrow.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
};

PrevArrow.defaultProps = {
	className: '',
	onClick: null,
};

const NextArrow = (props) => {
	const { onClick, className } = props;
	const [t] = useTranslation();
	const isDisabled = className && className.includes('slick-disabled');

	return (
		<button
			className={styles.sliderArrow}
			disabled={isDisabled}
			type="button"
			onClick={onClick}
		>
			<svg xmlns="http://www.w3.org/2000/svg" width={22} height={24} fill="none">
				<path fill="#47202A" d="m9.9 3.136 1.043-1.04a1.125 1.125 0 0 1 1.593 0l9.135 9.107c.442.44.442 1.153 0 1.59l-9.135 9.112c-.442.44-1.156.44-1.593 0l-1.044-1.04a1.124 1.124 0 0 1 .02-1.609l5.662-5.381H2.075A1.124 1.124 0 0 1 .947 12.75v-1.5c0-.623.503-1.125 1.128-1.125h13.506L9.918 4.744A1.116 1.116 0 0 1 9.9 3.136Z" />
			</svg>
			<p className={styles.text}>
				{t('clientCheck.profileSelect.next')}
			</p>
		</button>
	);
};

NextArrow.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
};

NextArrow.defaultProps = {
	className: '',
	onClick: null,
};

export default function AgreementAccountSelect({
	categories,
	category,
	childId,
	childrenList,
	selectCategory,
	selectChild,
}) {
	const sliderRef = useRef();
	const [t] = useTranslation();

	const items = [
		...categories.reduce((acc, contract) => {
			const active = contract === category;
			const name = t(`signNewAgreement.contractTypes.${contract}`);

			if (acc.some((item) => item.name === name && (!active || item.active))) {
				return acc;
			}

			return acc.filter((item) => item.name !== name).concat({
				key: contract,
				active,
				name,
				onClick: () => { selectCategory(contract); },
			});
		}, []),
		...childrenList.map((child) => ({
			key: child.id,
			active: child.id === childId,
			name: `${child.firstName} ${child.lastName}`,
			onClick: () => { selectChild(child.id); },
		})),
	];

	if (items.length <= 1) {
		return null;
	}

	return (
		<div className={styles.root}>
			<div className={styles.wrapper}>
				<Slider
					ref={sliderRef}
					arrows
					centerMode={false}
					className={styles.slider}
					infinite={false}
					nextArrow={<NextArrow />}
					prevArrow={<PrevArrow />}
					responsive={responsiveSettings}
					slidesToShow={6}
					swipe
					variableWidth
				>
					{items.map((item, index) => (
						<div
							key={item.key}
							className={`${styles.wrap} ${item.active ? styles.active : ''} ${index === items.length - 1 ? styles.last : ''}`.trim()}
						>
							<button
								className={styles.box}
								title={item.name}
								type="button"
								onClick={item.onClick}
							>
								<div className={styles.inner}>
									<span className={styles.icon}>
										<svg
											width={30}
											height={30}
											viewBox="0 0 64 64"
											xmlns="http://www.w3.org/2000/svg"
											fillRule="evenodd"
											clipRule="evenodd"
											strokeLinejoin="round"
											strokeMiterlimit="2"
										>
											<circle
												cx="26.202"
												cy="33.722"
												r="6.528"
												fill="#f49204"
												transform="translate(5.074 -26.936) scale(1.02762)"
											/>
											<circle
												cx="26.202"
												cy="33.722"
												r="6.528"
												fill="#f49204"
												transform="translate(-17.538 -26.619) scale(1.20166)"
											/>
											<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="matrix(.3802 0 0 .3802 -2.085 19.179)" />
											<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="matrix(.48317 0 0 .48317 1.289 33.752)" />
											<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(16.138 36.19) scale(.60537)" />
											<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(30.551 25.014) scale(.74228)" />
											<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(33.962 3.65) scale(.84072)" />
											<circle cx="26.202" cy="33.722" r="6.528" fill="#f49204" transform="translate(25.674 -17.054) scale(.9284)" />
										</svg>
									</span>
									<p className={styles.name}>
										{item.name}
									</p>
								</div>
							</button>
						</div>
					))}
				</Slider>
			</div>
		</div>
	);
}

AgreementAccountSelect.propTypes = {
	categories: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
	category: PropTypes.string,
	childId: PropTypes.number,
	childrenList: PropTypes.arrayOf(PropTypes.shape({
		firstName: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
		lastName: PropTypes.string.isRequired,
	}).isRequired).isRequired,
	selectCategory: PropTypes.func.isRequired,
	selectChild: PropTypes.func.isRequired,
};

AgreementAccountSelect.defaultProps = {
	category: null,
	childId: null,
};
