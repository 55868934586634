import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
	FooterCta,
	HomepageHero,
	HomepageJT,
	HomepageMedia,
	HomepageProducts,
	HomepageServices,
} from '../../components/common';

export default function Homepage({
	childProductUrl,
	classicProductUrl,
	esgProductUrl,
	future,
	howItWorksUrl,
	pensionProductUrl,
	registrationUrl,
	setFuture,
	studentProductUrl,
}) {
	const [t] = useTranslation();
	const productSectionAnchor = t('anchors.products');

	return (
		<>
			<HomepageHero
				future={future}
				howItWorksUrl={howItWorksUrl}
				productSectionAnchor={productSectionAnchor}
				setFuture={setFuture}
			/>
			<HomepageJT />
			<HomepageServices
				howItWorksUrl={howItWorksUrl}
			/>
			<HomepageProducts
				childProductUrl={childProductUrl}
				classicProductUrl={classicProductUrl}
				esgProductUrl={esgProductUrl}
				pensionProductUrl={pensionProductUrl}
				registrationUrl={registrationUrl}
				studentProductUrl={studentProductUrl}
			/>
			<HomepageMedia />
			<FooterCta registrationUrl={registrationUrl} />
		</>
	);
}

Homepage.propTypes = {
	childProductUrl: PropTypes.string.isRequired,
	classicProductUrl: PropTypes.string.isRequired,
	esgProductUrl: PropTypes.string.isRequired,
	future: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.string,
	]),
	howItWorksUrl: PropTypes.string.isRequired,
	pensionProductUrl: PropTypes.string.isRequired,
	registrationUrl: PropTypes.string.isRequired,
	setFuture: PropTypes.func,
	studentProductUrl: PropTypes.string.isRequired,
};

Homepage.defaultProps = {
	future: null,
	setFuture: null,
};
