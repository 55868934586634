import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useLanguage } from '../../../contexts/LocaleContext';
import {
	Col,
	Container,
	Row,
	Section,
} from '../../layout';
import { ExternalLink, Logo, Picture } from '../../ui';

import imgAppStore from './images/app_store.png';
import imgAppStoreWebP from './images/app_store.webp';
import imgAppStoreEn from './images/app_store_en.png';
import imgAppStoreWebPEn from './images/app_store_en.webp';
import imgAppStoreSk from './images/app_store_sk.png';
import imgAppStoreWebPSk from './images/app_store_sk.webp';
import imgAppStorePl from './images/app_store_pl.png';
import imgAppStoreWebPPl from './images/app_store_pl.webp';
import imgGooglePlay from './images/google_play.png';
import imgGooglePlayWebP from './images/google_play.webp';
import imgGooglePlayEn from './images/google_play_en.png';
import imgGooglePlayWebPEn from './images/google_play_en.webp';
import imgGooglePlaySk from './images/google_play_sk.png';
import imgGooglePlayWebPSk from './images/google_play_sk.webp';
import imgGooglePlayPl from './images/google_play_pl.png';
import imgGooglePlayWebPPl from './images/google_play_pl.webp';

import styles from './Footer.module.scss';

const HeartIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="12" height="12">
		<path
			fill="currentColor"
			d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"
		/>
	</svg>
);

const images = {
	cs: {
		appStore: {
			src: imgAppStore,
			srcWebP: imgAppStoreWebP,
		},
		googlePlay: {
			src: imgGooglePlay,
			srcWebP: imgGooglePlayWebP,
		},
	},
	en: {
		appStore: {
			src: imgAppStoreEn,
			srcWebP: imgAppStoreWebPEn,
		},
		googlePlay: {
			src: imgGooglePlayEn,
			srcWebP: imgGooglePlayWebPEn,
		},
	},
	sk: {
		appStore: {
			src: imgAppStoreSk,
			srcWebP: imgAppStoreWebPSk,
		},
		googlePlay: {
			src: imgGooglePlaySk,
			srcWebP: imgGooglePlayWebPSk,
		},
	},
	pl: {
		appStore: {
			src: imgAppStorePl,
			srcWebP: imgAppStoreWebPPl,
		},
		googlePlay: {
			src: imgGooglePlayPl,
			srcWebP: imgGooglePlayWebPPl,
		},
	},
};

export default function Footer({
	blogUrl,
	childProductUrl,
	classicProductUrl,
	clientZone,
	contactUrl,
	cookiesUrl,
	documentsUrl,
	esgProductUrl,
	faqUrl,
	feesUrl,
	homepageUrl,
	howItWorksUrl,
	ourApproachUrl,
	pensionProductUrl,
	privacyPolicyUrl,
	studentProductUrl,
}) {
	const [t] = useTranslation();
	const language = useLanguage();

	return (
		<footer
			role="contentinfo"
			className={styles.root}
		>
			<Section condensed>
				<Container>
					<Row>
						<Col>
							<div className={styles.header}>
								<div className={styles.logo}>
									<Logo />
								</div>
								<div className={styles.socials}>
									<ExternalLink
										className={styles.socialsLink}
										to={t('footer.primary.socialUrl.facebook')}
										blank
									>
										<svg viewBox="0 0 1024 1024" width={46} height={46} xmlns="http://www.w3.org/2000/svg">
											<path
												d="M1024 512C1024 229.23 794.77 0 512 0S0 229.23 0 512c0 255.554 187.231 467.37 432 505.78V660H302V512h130V399.2C432 270.88 508.438 200 625.39 200 681.407 200 740 210 740 210v126h-64.562C611.835 336 592 375.467 592 415.957V512h142l-22.7 148H592v357.78c244.769-38.41 432-250.226 432-505.78z"
												fill="#1877f2"
											/>
											<path
												d="M711.3 660L734 512H592v-96.043c0-40.49 19.835-79.957 83.438-79.957H740V210s-58.593-10-114.61-10C508.438 200 432 270.88 432 399.2V512H302v148h130v357.78a515.834 515.834 0 0080 6.22c27.216 0 53.933-2.13 80-6.22V660h119.3z"
												fill="#fff"
											/>
										</svg>
									</ExternalLink>
									<ExternalLink
										className={styles.socialsLink}
										to={t('footer.primary.socialUrl.instagram')}
										blank
									>
										<svg viewBox="0 0 128 128" width={46} height={46} xmlns="http://www.w3.org/2000/svg">
											<linearGradient
												gradientTransform="matrix(1 0 0 -1 594 633)"
												gradientUnits="userSpaceOnUse"
												id="instagramLogo"
												x1="-566.711"
												x2="-493.288"
												y1="516.569"
												y2="621.43"
											>
												<stop offset="0" stopColor="#ffb900" />
												<stop offset="1" stopColor="#9100eb" />
											</linearGradient>
											<circle cx="64" cy="64" fill="url(#instagramLogo)" r="64" />
											<g>
												<path
													d="M82.333 104H45.667C33.72 104 24 94.281 24 82.333V45.667C24 33.719 33.72 24 45.667 24h36.666C94.281 24 104 33.719 104 45.667v36.667C104 94.281 94.281 104 82.333 104zM45.667 30.667c-8.271 0-15 6.729-15 15v36.667c0 8.271 6.729 15 15 15h36.666c8.271 0 15-6.729 15-15V45.667c0-8.271-6.729-15-15-15H45.667z"
													fill="#FFF"
												/>
												<path
													d="M64 84c-11.028 0-20-8.973-20-20 0-11.029 8.972-20 20-20s20 8.971 20 20c0 11.027-8.972 20-20 20zm0-33.333c-7.352 0-13.333 5.981-13.333 13.333 0 7.353 5.981 13.333 13.333 13.333S77.333 71.353 77.333 64c0-7.352-5.98-13.333-13.333-13.333z"
													fill="#FFF"
												/>
												<circle cx="85.25" cy="42.75" fill="#FFF" r="4.583" />
											</g>
										</svg>
									</ExternalLink>
									<ExternalLink
										className={styles.socialsLink}
										to={t('footer.primary.socialUrl.linkedin')}
										blank
									>
										<svg viewBox="0 0 1024 1024" width={46} height={46}>
											<circle cx="56.098" cy="56.097" r="56.098" fill="#007ab9" transform="translate(0 .008) scale(9.12689)" />
											<path
												d="M817.915 553.198v211.087H695.533v-196.94c0-49.45-17.67-83.22-61.98-83.22-33.816 0-53.904 22.736-62.776 44.75-3.221 7.867-4.052 18.792-4.052 29.827v205.574H444.334s1.642-333.552 0-368.079h122.4v52.16c-.246.411-.593.813-.812 1.205h.812v-1.205c16.264-25.025 45.27-60.803 110.299-60.803 80.517 0 140.882 52.608 140.882 165.644zM316.301 218.771c-41.865 0-69.254 27.48-69.254 63.587 0 35.339 26.595 63.614 67.648 63.614h.794c42.687 0 69.228-28.275 69.228-63.614-.813-36.106-26.541-63.587-68.416-63.587zm-61.98 545.514h122.346V396.197H254.32v368.088z"
												fill="#f1f2f2"
												fillRule="nonzero"
											/>
										</svg>
									</ExternalLink>
									{language !== 'pl' && (
										<ExternalLink
											className={styles.socialsLink}
											to={t('footer.primary.socialUrl.twitter')}
											blank
										>
											<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={45} height={44} viewBox="0 0 45 44">
												<path fill="#000" d="M22 43.9996C34.1503 43.9996 44 34.1499 44 21.9996C44 9.84937 34.1503 -0.000366211 22 -0.000366211C9.84974 -0.000366211 0 9.84937 0 21.9996C0 34.1499 9.84974 43.9996 22 43.9996Z" />
												<path fill="#fff" d="M24.0951 20.3165L32.2865 11H30.3461L23.2305 19.0878L17.5516 11H11L19.5895 23.2313L11 32.9999H12.9404L20.4497 24.4571L26.4484 32.9999H33M13.6407 12.4317H16.6217L30.3446 31.6385H27.3629" />
											</svg>
										</ExternalLink>
									)}
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className={styles.content}>
								<div className={styles.legal}>
									<p className={styles.title}>
										{t('footer.secondary.title')}
									</p>
									<address className={styles.text}>
										<Trans i18nKey="footer.secondary.address">
											<br />
										</Trans>
									</address>
									<p className={styles.text}>
										<strong>{t('footer.secondary.info')}</strong>
									</p>
									<p className={styles.text}>
										{t('footer.secondary.legal')}
									</p>
									<p className={styles.text}>
										<Trans i18nKey="footer.secondary.love">
											<HeartIcon />
										</Trans>
									</p>
								</div>
								<div className={styles.navigation}>
									<p className={styles.title}>
										{t('footer.primary.nav.title')}
									</p>
									<nav>
										<ul className={styles.list}>
											<li>
												<Link
													className={styles.link}
													onClick={(e) => e.currentTarget.blur()}
													to={homepageUrl}
												>
													{t('footer.primary.nav.homepage')}
												</Link>
											</li>
											<li>
												<Link
													className={styles.link}
													onClick={(e) => e.currentTarget.blur()}
													to={howItWorksUrl}
												>
													{t('footer.primary.nav.howItWorks')}
												</Link>
											</li>
											<li>
												<Link
													className={styles.link}
													onClick={(e) => e.currentTarget.blur()}
													to={ourApproachUrl}
												>
													{t('footer.primary.nav.ourApproach')}
												</Link>
											</li>
											<li>
												<Link
													className={styles.link}
													onClick={(e) => e.currentTarget.blur()}
													to={feesUrl}
												>
													{t('footer.primary.nav.fees')}
												</Link>
											</li>
											{clientZone && (
												<li>
													<Link
														className={styles.link}
														onClick={(e) => e.currentTarget.blur()}
														to={faqUrl}
													>
														{t('footer.primary.nav.faq')}
													</Link>
												</li>
											)}
											<li>
												<Link
													className={styles.link}
													onClick={(e) => e.currentTarget.blur()}
													to={contactUrl}
												>
													{t('footer.primary.nav.contact')}
												</Link>
											</li>
											<li>
												<Link
													className={styles.link}
													onClick={(e) => e.currentTarget.blur()}
													to={documentsUrl}
												>
													{t('footer.primary.nav.documents')}
												</Link>
											</li>
											{blogUrl !== null && (
												<li>
													<Link
														className={styles.link}
														onClick={(e) => e.currentTarget.blur()}
														to={blogUrl}
													>
														{t('footer.primary.nav.blog')}
													</Link>
												</li>
											)}
											<li>
												<Link
													className={styles.link}
													onClick={(e) => e.currentTarget.blur()}
													to={cookiesUrl}
												>
													{t('footer.primary.nav.cookies')}
												</Link>
											</li>
											<li>
												<Link
													className={styles.link}
													onClick={(e) => e.currentTarget.blur()}
													to={privacyPolicyUrl}
												>
													{t('footer.primary.nav.privacyPolicy')}
												</Link>
											</li>
										</ul>
									</nav>
								</div>
								<div className={styles.products}>
									<p className={styles.title}>
										{t('footer.primary.products.title')}
									</p>
									<nav>
										<ul className={styles.list}>
											<li>
												<Link
													className={styles.link}
													onClick={(e) => e.currentTarget.blur()}
													to={classicProductUrl}
												>
													{t('footer.primary.products.classic')}
												</Link>
											</li>
											<li>
												<Link
													className={styles.link}
													onClick={(e) => e.currentTarget.blur()}
													to={esgProductUrl}
												>
													{t('footer.primary.products.esg')}
												</Link>
											</li>
											<li>
												<Link
													className={styles.link}
													onClick={(e) => e.currentTarget.blur()}
													to={childProductUrl}
												>
													{t('footer.primary.products.children')}
												</Link>
											</li>
											<li>
												<Link
													className={styles.link}
													onClick={(e) => e.currentTarget.blur()}
													to={pensionProductUrl}
												>
													{t('footer.primary.products.pension')}
												</Link>
											</li>
											<li>
												<Link
													className={styles.link}
													onClick={(e) => e.currentTarget.blur()}
													to={studentProductUrl}
												>
													{t('footer.primary.products.student')}
												</Link>
											</li>
										</ul>
									</nav>
								</div>
								<div className={styles.appWrap}>
									<p className={styles.appTitle}>
										{t('footer.primary.app')}
									</p>
									<ExternalLink
										blank
										className={styles.appLink}
										to="https://apps.apple.com/cz/app/fondee/id1616141130?&utm_source=web_footer_android&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1itsct=apps_box_badge&amp;itscg=30200"
									>
										<Picture
											alt={t('footer.primary.appStore')}
											className={styles.storeImage}
											src={images[language].appStore.src}
											srcWebP={images[language].appStore.srcWebP}
										/>
									</ExternalLink>
									<ExternalLink
										blank
										className={styles.appLink}
										to="https://play.google.com/store/apps/details?id=cz.fondee.app&utm_source=web_footer&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
									>
										<Picture
											alt={t('footer.primary.googlePlay')}
											className={styles.storeImage}
											src={images[language].googlePlay.src}
											srcWebP={images[language].googlePlay.srcWebP}
										/>
									</ExternalLink>
								</div>

							</div>
						</Col>
					</Row>
				</Container>
			</Section>
		</footer>
	);
}

Footer.propTypes = {
	blogUrl: PropTypes.string,
	childProductUrl: PropTypes.string.isRequired,
	classicProductUrl: PropTypes.string.isRequired,
	clientZone: PropTypes.bool,
	contactUrl: PropTypes.string.isRequired,
	cookiesUrl: PropTypes.string.isRequired,
	documentsUrl: PropTypes.string.isRequired,
	esgProductUrl: PropTypes.string.isRequired,
	faqUrl: PropTypes.string.isRequired,
	feesUrl: PropTypes.string.isRequired,
	homepageUrl: PropTypes.string.isRequired,
	howItWorksUrl: PropTypes.string.isRequired,
	ourApproachUrl: PropTypes.string.isRequired,
	pensionProductUrl: PropTypes.string.isRequired,
	privacyPolicyUrl: PropTypes.string.isRequired,
	studentProductUrl: PropTypes.string.isRequired,
};

Footer.defaultProps = {
	blogUrl: null,
	clientZone: false,
};
