import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { OnboardingAgreement, ProgressIcon } from '../../../components/common';

import styles from './SignNewAgreementSteps.module.scss';

export default function SignNewAgreementSteps({
	category,
	childId,
	setCheckOnboarding,
}) {
	const [t] = useTranslation();

	return (
		<ul className={styles.root}>
			<li id="step5" className={styles.step}>
				<div className={styles.stepHeader}>
					<div className={styles.stepNumber}>
						01
						<span className={styles.stepIcon}>
							<ProgressIcon completed={false} />
						</span>
					</div>
					<div className={styles.stepInfo}>
						<h2 className={styles.stepTitle}>{t('onboarding.steps.agreement.title')}</h2>
					</div>
				</div>

				<div className={styles.stepContent}>
					<p>
						{t('onboarding.steps.agreement.signNewAgreementActive')}
					</p>
				</div>

				<div className={styles.stepContent}>
					<OnboardingAgreement
						key={childId ?? category}
						category={childId !== null ? 'child' : category}
						childId={childId}
						newAgreement
						setCheckOnboarding={setCheckOnboarding}
					/>
				</div>
			</li>
		</ul>
	);
}

SignNewAgreementSteps.propTypes = {
	category: PropTypes.string,
	childId: PropTypes.number,
	setCheckOnboarding: PropTypes.func.isRequired,
};

SignNewAgreementSteps.defaultProps = {
	category: null,
	childId: null,
};
