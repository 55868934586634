import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './ProductTerms.module.scss';

export default function ProductTerms({
	blogPostEtfUrl,
	blogPostFinancesUrl,
	blogPostLongTermUrl,
	esgProductUrl,
	product,
	registerUrl,
}) {
	const [t] = useTranslation();

	return (
		<div className={styles.wrap}>
			<h3 className={styles.title}>
				{t(`products.${product}.terms.title`)}
			</h3>
			<Trans i18nKey={`products.${product}.terms.text`}>
				<p className={styles.subTitle} />
				<p className={styles.text}>
					<strong>
						{blogPostEtfUrl ? <Link to={blogPostEtfUrl} target="_blank" /> : <></>}
						{registerUrl ? <Link to={registerUrl} target="_blank" /> : <></>}
						{esgProductUrl ? <Link to={esgProductUrl} target="_blank" /> : <></>}
						{blogPostFinancesUrl ? <Link to={blogPostFinancesUrl} target="_blank" /> : <></>}
					</strong>
					{blogPostLongTermUrl ? <Link to={blogPostLongTermUrl} target="_blank" /> : <></>}
					<ul className={styles.list}>
						<li className={styles.listItem}>
							<strong>
								{registerUrl ? <Link to={registerUrl} target="_blank" /> : <></>}
							</strong>
						</li>
					</ul>
					{blogPostEtfUrl ? <Link to={blogPostEtfUrl} target="_blank" /> : <></>}
				</p>
			</Trans>
		</div>
	);
}

ProductTerms.propTypes = {
	blogPostEtfUrl: PropTypes.string,
	blogPostFinancesUrl: PropTypes.string,
	blogPostLongTermUrl: PropTypes.string,
	esgProductUrl: PropTypes.string,
	product: PropTypes.string.isRequired,
	registerUrl: PropTypes.string,
};

ProductTerms.defaultProps = {
	blogPostEtfUrl: null,
	blogPostFinancesUrl: null,
	blogPostLongTermUrl: null,
	esgProductUrl: null,
	registerUrl: null,
};
