import React from 'react';
import PropTypes from 'prop-types';

import { Trans, useTranslation } from 'react-i18next';

import { Col, Container, Row } from '../../../components/layout';
import { EmailLink } from '../../../components/ui';
import StudentConfirmationUpload from './StudentConfirmationUpload';

import styles from './StudentConfirmation.module.scss';

export default function StudentConfirmation({
	documentVerification,
	reloadUser,
}) {
	const [t] = useTranslation();

	return (
		<Container>
			<Row>
				<Col xl={6} lg={8}>
					{documentVerification ? (
						<>
							<p className={styles.title}>
								{t('studentConfirmation.account.verification.title')}
							</p>
							<p className={styles.text}>
								<Trans i18nKey="studentConfirmation.account.verification.text">
									<EmailLink />
								</Trans>
							</p>
						</>
					) : (
						<>
							<p className={styles.text}>
								{t('studentConfirmation.account.text')}
							</p>
							<StudentConfirmationUpload reloadUser={reloadUser} />
						</>
					)}
				</Col>
			</Row>
		</Container>
	);
}

StudentConfirmation.propTypes = {
	documentVerification: PropTypes.bool.isRequired,
	reloadUser: PropTypes.func.isRequired,
};
