import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import { useUserContext } from '../../../contexts/UserContext';
import {
	createPortfolio,
	fetchChildrenList,
	fetchInvestorProfile,
	fetchProfileInvestmentProfile,
	fetchShowEsgWarning,
} from '../../../utils/api';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import useFetch from '../../../utils/useFetch';
import useFetchAuth from '../../../utils/useFetchAuth';
import useRequestAuth from '../../../utils/useRequestAuth';
import { validatePortfolioCreate } from '../../../utils/validators';
import {
	CheckboxField,
	RadioList,
	TextField,
} from '../../forms';
import {
	Alert,
	Button,
	ButtonLink,
	ExternalLink,
	Modal,
	Toggle,
	Tooltip,
} from '../../ui';
import InvestorProfileDetailSelection from '../InvestorProfileDetail/InvestorProfileDetailSelection';
import InvestorProfileDetailSlider from '../InvestorProfileDetail/InvestorProfileDetailSlider';
import ProductSelect from '../ProductSelect';
import ChildList from './ChildList';

import styles from './PortfolioCreate.module.scss';

const useFetchChildrenList = useFetchAuth(fetchChildrenList);
const useFetchInvestorProfile = useFetch(fetchInvestorProfile);
const useFetchProfileInvestmentProfile = useFetchAuth(
	(enabled, childId) => (enabled ? fetchProfileInvestmentProfile(null, childId) : Promise.resolve({})),
);
const useFetchShowEsgWarning = useFetchAuth(fetchShowEsgWarning);

const getProfileIndex = (profiles, profileId, defaultIndex = null) => {
	if (!profiles) {
		return defaultIndex;
	}

	for (let i = 0; i < profiles.length; i += 1) {
		if (profiles[i].id === profileId) {
			return i;
		}
	}

	return defaultIndex;
};

const getToggleName = (header, label, noActivePortfolio, suffix) => {
	if (header || noActivePortfolio) {
		return `toggle-create-portfolio-${header ? 'header' : 'no-active-portfolio'}-${label || ''}-${suffix}`;
	}
	return `toggle-create-portfolio-${suffix}`;
};

const getInitialCategory = (category) => (
	category === 'standard' || category === 'child' || category === 'pension' ? category : null
);

const getInitialProduct = (category = null) => [category ?? 'standard', null, null, false];

const getProductWarning = (portfolios, product) => {
	const [category, , childId] = product;

	if (portfolios === null) {
		return null;
	}

	if (category === 'child') {
		const activeChildCount = childId !== null
			? portfolios.active.filter((portfolio) => portfolio.child?.id === childId).length
			: 0;
		return activeChildCount >= 3 ? 'limitChild' : null;
	}

	const activeCount = portfolios.active.filter((portfolio) => portfolio.child === null).length;
	const activePensionCount = portfolios.active.filter(
		(portfolio) => portfolio.child === null && portfolio.categoryName === 'pension',
	).length;

	if (activeCount >= 5) {
		return 'limit';
	}

	if (category === 'pension' && activePensionCount >= 1) {
		return 'limitPension';
	}

	return null;
};

const updateCategory = (nextCategory) => (product) => {
	const [category, warning, childId, productSelected] = product;
	const valid = warning === null && childId === null && !productSelected;
	return valid && nextCategory !== category ? [nextCategory, null, null, false] : product;
};

const updateWarning = (nextWarning) => (product) => {
	const [category, warning, childId, productSelected] = product;
	const valid = childId === null && !productSelected;
	return valid && nextWarning !== warning ? [category, nextWarning, null, false] : product;
};

const updateChildId = (portfolios, nextChildId) => (product) => {
	const [category, warning, childId, productSelected] = product;
	const valid = category === 'child' && warning === null && childId === null && !productSelected;

	if (!valid || portfolios === null || nextChildId === null) {
		return product;
	}

	const nextProduct = [category, null, nextChildId, true];
	const nextWarning = getProductWarning(portfolios, nextProduct);
	return nextWarning === null ? nextProduct : [category, nextWarning, null, false];
};

const updateProductSelected = (portfolios) => (product) => {
	const [category, warning, childId, productSelected] = product;
	const valid = category !== 'child' && warning === null && childId === null && !productSelected;

	if (!valid || portfolios === null) {
		return product;
	}

	const nextProduct = [category, null, null, true];
	const nextWarning = getProductWarning(portfolios, nextProduct);
	return nextWarning === null ? nextProduct : [category, nextWarning, null, false];
};

const initialEsg = [false, false];

const updateEsgToggle = (nextEsgToggle) => (esg) => {
	const [esgToggle] = esg;
	return nextEsgToggle !== esgToggle ? [nextEsgToggle, false] : esg;
};

const updateEsgWarningDismissed = (esg) => {
	const [esgToggle, esgWarningDismissed] = esg;
	return !esgWarningDismissed ? [esgToggle, true] : esg;
};

export default function PortfolioCreate({
	accountUrl,
	childOnboardingUrl,
	conditionsUrl,
	currencies,
	header,
	label,
	noActivePortfolio,
	parentOnboardingUrl,
	portfolios,
	reloadDashboard,
	reloadPortfolios,
	setScrollDashboard,
	signNewAgreementUrl,
}) {
	const [t] = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const country = useCountry();
	const language = useLanguage();
	const [user] = useUserContext();

	const createPortfolioAuth = useRequestAuth(createPortfolio);

	const state = !header && !noActivePortfolio ? location.state : null;
	const [modalOpen, setModalOpen] = useState(!!state?.createPortfolio);

	const initialCategory = getInitialCategory(state?.category);
	const [product, setProduct] = useState(getInitialProduct(initialCategory));
	const [category, productWarning, childId, productSelected] = product;

	const childCategory = category === 'child';
	const consentRequired = user?.documents?.consentRequired?.[category];
	const contractRequired = user?.documents?.contractRequired?.[category];
	const createRedirectUrl = (!childCategory && user?.child_only_client ? parentOnboardingUrl : null)
		?? (!childCategory && contractRequired ? signNewAgreementUrl : null)
		?? null;
	const createRedirectState = !childCategory && contractRequired ? { createPortfolio: true } : null;

	const [[esgToggle, esgWarningDismissed], setEsg] = useState(initialEsg);
	const [selectedProfile, setSelectedProfile] = useState(null);
	const [notRecommendedModalOpen, setNotRecommendedModalOpen] = useState(false);
	const [error, setError] = useState(false);
	const [errorClosure, setErrorClosure] = useState(false);
	const [success, setSuccess] = useState(false);
	const [userClosureWarning, setUserClosureWarning] = useState(false);

	const [childrenList] = useFetchChildrenList(null);
	const [profiles, loadingProfiles] = useFetchInvestorProfile({}, country, language);
	const fetchProfile = childCategory ? childId !== null : createRedirectUrl === null;
	const [investmentProfile, loadingInvestmentProfile] = useFetchProfileInvestmentProfile({}, fetchProfile, childId);
	const [showEsgWarning] = useFetchShowEsgWarning(false, childId);
	const trackGTMEvent = useTrackGTMEvent();

	const setCategory = useCallback((nextCategory) => setProduct(updateCategory(nextCategory)), []);
	const setProductWarning = useCallback((nextWarning) => setProduct(updateWarning(nextWarning)), []);
	const selectChild = useCallback((nextChildId) => setProduct(updateChildId(portfolios, nextChildId)), [portfolios]);
	const selectProduct = useCallback(() => setProduct(updateProductSelected(portfolios)), [portfolios]);

	const setEsgToggle = useCallback((nextEsgToggle) => {
		const nextRecommendedProfiles = nextEsgToggle
			? investmentProfile.recommendedProfilesEsg
			: investmentProfile.recommendedProfiles;
		const nextDefaultProfile = nextEsgToggle
			? investmentProfile.defaultProfileEsg
			: investmentProfile.defaultProfile;

		if (
			loadingInvestmentProfile
			|| !nextRecommendedProfiles
			|| nextRecommendedProfiles.length === 0
			|| !nextDefaultProfile
		) {
			return;
		}

		setSelectedProfile(null);
		setEsg(updateEsgToggle(nextEsgToggle));
	}, [investmentProfile, loadingInvestmentProfile]);
	const setEsgWarningDismissed = useCallback(() => setEsg(updateEsgWarningDismissed), []);

	const esgAvailable = !loadingInvestmentProfile
		&& !!investmentProfile.recommendedProfilesEsg
		&& investmentProfile.recommendedProfilesEsg.length > 0
		&& !!investmentProfile.defaultProfileEsg;
	const esgActive = esgAvailable && esgToggle;

	const investorProfiles = esgActive ? profiles.investorProfilesEsg : profiles.investorProfiles;
	const defaultProfile = esgActive ? investmentProfile.defaultProfileEsg : investmentProfile.defaultProfile;
	const recommendedProfiles = esgActive
		? investmentProfile.recommendedProfilesEsg
		: investmentProfile.recommendedProfiles;
	const currentProfile = selectedProfile ?? defaultProfile ?? null;
	const currentSlide = getProfileIndex(investorProfiles, currentProfile, 3);

	const setCurrentSlide = useCallback((profileId) => {
		const { id = null } = investorProfiles?.[profileId] ?? {};
		if (id === null || id === selectedProfile) {
			return;
		}

		setSelectedProfile(id);
		if (recommendedProfiles === null || recommendedProfiles?.includes(id) === false) {
			setNotRecommendedModalOpen(true);
		}
	}, [selectedProfile, investorProfiles, recommendedProfiles]);

	const userStudent = user?.user_type === 4;
	const userAge = user?.birth_date
		? new Date().getFullYear() - new Date(user?.birth_date).getFullYear()
		- (new Date() < new Date(user?.birth_date).setFullYear(new Date().getFullYear()) ? 1 : 0)
		: null;
	const userStudentEligibility = userStudent || (userAge !== null && userAge >= 18 && userAge < 26);

	if (loadingProfiles || currencies === null || portfolios === null || user === null) {
		return null;
	}

	const currencyOptions = currencies.map(({ code }) => ({
		label: code,
		value: code,
	}));

	const initialProductSelected = initialCategory !== null
		&& (category === 'standard' || category === 'pension') && productWarning === null && childId === null
		&& createRedirectUrl === null
		&& getProductWarning(portfolios, product) === null;

	const handleButtonClick = () => {
		if (user.account_closure) {
			setUserClosureWarning(true);
			return;
		}
		setModalOpen(true);

		trackGTMEvent('interactions', {
			eventCategory: 'interactions',
			eventAction: 'buttonClick',
			eventLabel: 'add_portfolio',
		});
	};

	const handleClose = () => {
		setModalOpen(false);
		setProduct(getInitialProduct());
		setEsg(initialEsg);
		setSelectedProfile(null);
		setNotRecommendedModalOpen(false);
		setError(false);
		setErrorClosure(false);
		setUserClosureWarning(false);
		if (!header && !noActivePortfolio) {
			navigate({}, { replace: true });
		}
	};

	const handleRedirect = (e) => {
		const nextWarning = getProductWarning(portfolios, product);
		if (nextWarning !== null) {
			setProductWarning(nextWarning);
			e.preventDefault();
		} else {
			handleClose();
		}
	};

	const handleSuccessClose = () => {
		setSuccess(false);
		if (setScrollDashboard !== null) {
			setScrollDashboard(true);
		}
		if (reloadDashboard !== null) {
			reloadDashboard();
		}
		if (reloadPortfolios !== null) {
			reloadPortfolios();
		}
	};

	return (
		<div className={`${styles.root} ${header ? styles.noOffset : ''}`.trim()}>
			{header || noActivePortfolio ? (
				<button
					className={header ? styles.headerButton : styles.button}
					onClick={handleButtonClick}
					title={header ? t('portfolio.select.button') : undefined}
					type="button"
				>
					{header ? (
						<>
							{t('portfolio.select.button')}
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={24} height={24} viewBox="0 0 24 24">
								<circle cx="12" cy="12" r="11" fill="#fff" stroke="#EBEBEB" strokeWidth="2" />
								<path stroke="#F39200" strokeLinecap="round" strokeWidth="2" d="M7.64 12h8.72M12 16.36V7.64" />
							</svg>
						</>
					) : (
						<>
							<svg viewBox="0 0 26 24" width="20" height="22" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
								<path d="M14.695 9.805V1.5h-3.39v8.305H3v3.39h8.305V21.5h3.39v-8.305H23v-3.39h-8.305z" fill="currentColor" />
							</svg>
							{t('portfolio.new.create')}
						</>
					)}
				</button>
			) : (
				<Button
					label={t('portfolio.new.create')}
					onClick={handleButtonClick}
				/>
			)}

			<Modal
				isVisible={modalOpen}
				onClose={handleClose}
				size="large"
				title={t('portfolio.new.modal.title')}
				subtitle={t('portfolio.new.modal.subtitle')}
			>
				{!productSelected && !initialProductSelected ? (
					<div className={styles.wrap}>
						<ProductSelect
							accountUrl={accountUrl}
							category={category}
							handleClose={handleClose}
							setCategory={setCategory}
							suffix={label}
							userStudent={userStudent}
							userStudentEligibility={userStudentEligibility}
						/>
						{childCategory && (
							<ChildList
								childOnboardingUrl={childOnboardingUrl}
								childrenList={childrenList}
								handleRedirect={handleRedirect}
								modalClose={handleClose}
								selectChild={selectChild}
								signNewAgreementUrl={signNewAgreementUrl}
							/>
						)}
						{childCategory && (
							<p className={styles.modalText}>
								{t('portfolio.new.modal.childInfo')}
							</p>
						)}
						{!childCategory && (createRedirectUrl !== null ? (
							<ButtonLink
								label={t('portfolio.new.modal.add')}
								onClick={handleRedirect}
								state={createRedirectState}
								to={`${createRedirectUrl}?category=${category}`}
							/>
						) : (
							<Button
								label={t('portfolio.new.modal.add')}
								onClick={selectProduct}
							/>
						))}
					</div>
				) : !loadingInvestmentProfile && (
					<Formik
						initialValues={{
							portfolioName: '',
							currency: currencies.find(({ selected }) => selected)?.code ?? null,
							rebalancingActive: true,
							newConsent: false,
						}}
						validate={(values) => validatePortfolioCreate(values)}
						onSubmit={async (values, { setErrors }) => {
							setSuccess(false);
							setErrorClosure(false);
							try {
								await createPortfolioAuth(
									childId,
									category,
									values.portfolioName,
									currentProfile,
									values.currency,
									!values.rebalancingActive,
									values.newConsent,
								);
							} catch (e) {
								const fieldErrors = {};
								const errorMessage = e.responseJson?.message;
								if (typeof errorMessage === 'string') {
									if ((errorMessage.indexOf('name uniqueness')) !== -1) {
										fieldErrors.portfolioName = 'portfolio.new.form.name.unique';
									} else if ((errorMessage.indexOf('Account closure is active')) !== -1) {
										setErrorClosure(true);
									}
								}
								const hasFieldErrors = Object.keys(fieldErrors).length > 0;
								if (hasFieldErrors) {
									setErrors(fieldErrors);
								}
								setError(!hasFieldErrors);
								return;
							}
							handleClose();
							setSuccess(true);
						}}
					>
						{({
							errors,
							handleBlur,
							handleChange,
							handleSubmit,
							isSubmitting,
							touched,
							values,
						}) => (
							<div className={styles.formWrap}>
								<form onSubmit={handleSubmit}>
									{error && !errorClosure && (
										<Alert type="danger">
											{t('forms.error')}
										</Alert>
									)}
									{errorClosure && (
										<Alert type="danger">
											<Trans i18nKey="portfolio.new.modal.errorClosure">
												<Link
													to={`${accountUrl}#${t('anchors.accountClosure')}`}
													onClick={handleClose}
												/>
											</Trans>
										</Alert>
									)}
									{esgAvailable && (
										<div className={styles.toggleWrap}>
											<div className={styles.toggleLabel}>
												{t('investorProfilePreview.toggleLabel')}
											</div>
											<Toggle
												inputNameDefault={getToggleName(header, label, noActivePortfolio, 'classic')}
												inputNameSecondary={getToggleName(header, label, noActivePortfolio, 'esg')}
												labelFirst={t('investorProfilePreview.classicPortfolios')}
												labelSecond={t('investorProfilePreview.esgPortfolios')}
												setToggle={setEsgToggle}
												toggle={esgActive}
												tooltipFirst={t('investorProfileChoice.tooltip.classic')}
												tooltipSecond={t('investorProfileChoice.tooltip.esg')}
											/>
											{esgActive ? (
												<p>
													{t('investorProfileChoice.category.esg')}
												</p>
											) : (
												<p>
													{t('investorProfileChoice.category.classic')}
												</p>
											)}
										</div>
									)}
									<InvestorProfileDetailSlider
										currentSlide={currentSlide}
										products={profiles.products ?? []}
										profiles={investorProfiles ?? []}
										setCurrentSlide={setCurrentSlide}
									/>
									<InvestorProfileDetailSelection
										currentSlide={currentSlide}
										onSelectionChange={setCurrentSlide}
										profiles={investorProfiles ?? []}
										profilesRecommend={recommendedProfiles ?? null}
									/>
									<div className={styles.options}>
										<TextField
											error={
												errors.portfolioName
											&& touched.portfolioName
											&& t(errors.portfolioName)
											}
											id="portfolioName"
											label={t('portfolio.new.modal.input')}
											maxLength={30}
											minLength={1}
											name="portfolioName"
											onBlur={handleBlur}
											onChange={handleChange}
											required
											tooltip={t('portfolio.new.modal.nameTooltip')}
											type="text"
											value={values.portfolioName}
										/>
										<h4 className={styles.title}>
											{t('portfolio.new.modal.currency')}
											<Tooltip
												text={t('portfolio.new.modal.currencyTooltip')}
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 512 512"
													width={15}
													className={styles.tooltip}
												>
													<path
														fill="currentColor"
														d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
													/>
												</svg>
											</Tooltip>
										</h4>
										<RadioList
											disabled={isSubmitting}
											id="currency"
											name="currency"
											onChange={handleChange}
											options={currencyOptions}
											value={values.currency}
										/>
										<h4 className={styles.title}>{t('portfolio.new.modal.additional')}</h4>
										<CheckboxField
											checked={values.rebalancingActive}
											disabled={isSubmitting}
											id="rebalancingActive"
											label={t('portfolio.new.modal.rebalancingActive')}
											name="rebalancingActive"
											onBlur={handleBlur}
											onChange={handleChange}
											tooltip={t('portfolio.new.modal.rebalancingTooltip')}
										/>
										{consentRequired && (
											<CheckboxField
												checked={values.newConsent}
												disabled={isSubmitting}
												id="newConsent"
												label={(
													<Trans i18nKey={category === 'pension' ? 'portfolio.new.modal.newConsentPension' : 'portfolio.new.modal.newConsent'}>
														<ExternalLink
															to={conditionsUrl}
															blank
														/>
													</Trans>
												)}
												name="newConsent"
												onBlur={handleBlur}
												onChange={handleChange}
												required
											/>
										)}
									</div>
									<div className={styles.modalFooter}>
										<Button
											disabled={isSubmitting}
											isSubmit
											label={t('portfolio.new.modal.add')}
											onClick={() => trackGTMEvent('interactions', {
												eventCategory: 'interactions',
												eventAction: 'buttonClick',
												eventLabel: 'add_portfolio_pop_up',
											})}
										/>
									</div>
								</form>
								<Modal
									isVisible={showEsgWarning && esgActive && !esgWarningDismissed}
									onClose={setEsgWarningDismissed}
									title={t('general.esgWarningModal.title')}
								>
									<div className={styles.modal}>
										<p>{t('general.esgWarningModal.text')}</p>
										<div className={styles.modalFooter}>
											<Button
												label={t('general.esgWarningModal.button')}
												onClick={setEsgWarningDismissed}
											/>
										</div>
									</div>
								</Modal>
								<Modal
									isVisible={notRecommendedModalOpen}
									onClose={() => {
										setSelectedProfile(null);
										setNotRecommendedModalOpen(false);
									}}
									title={t('investorProfileChoice.notRecommended.title')}
								>
									<div className={styles.modal}>
										<Trans i18nKey="investorProfileChoice.notRecommended.clientMessage">
											<p className={styles.modalText} />
										</Trans>
										<Button
											label={t('investorProfileChoice.notRecommended.clientClose')}
											onClick={() => {
												setSelectedProfile(null);
												setNotRecommendedModalOpen(false);
											}}
										/>
									</div>
								</Modal>
							</div>
						)}
					</Formik>
				)}
			</Modal>
			<Modal
				isVisible={productWarning !== null}
				onClose={() => setProductWarning(null)}
				title={productWarning !== null ? t(`portfolio.new.modal.warning.${productWarning}.title`) : ''}
			>
				<div className={styles.formWrap}>
					<p>{productWarning !== null ? t(`portfolio.new.modal.warning.${productWarning}.text`) : ''}</p>
					<div className={styles.modalFooter}>
						<Button
							label={t('portfolio.new.modal.close')}
							onClick={() => setProductWarning(null)}
						/>
					</div>
				</div>
			</Modal>
			<Modal
				isVisible={success}
				onClose={handleSuccessClose}
				title={t('portfolio.new.modal.success.title')}
			>
				<div className={styles.formWrap}>
					<p>{t('portfolio.new.modal.success.text')}</p>
					<div className={styles.modalFooter}>
						<Button
							label={t('portfolio.new.modal.close')}
							onClick={handleSuccessClose}
						/>
					</div>
				</div>
			</Modal>
			<Modal
				isVisible={userClosureWarning}
				onClose={() => setUserClosureWarning(false)}
			>
				<div className={styles.formWrap}>
					<p className={styles.warning}>
						<Trans i18nKey="portfolio.new.modal.errorClosure">
							<Link
								to={`${accountUrl}#${t('anchors.accountClosure')}`}
								onClick={handleClose}
							/>
						</Trans>
					</p>
					<Button
						label={t('portfolio.new.modal.close')}
						onClick={() => setUserClosureWarning(false)}
					/>
				</div>
			</Modal>
		</div>
	);
}

PortfolioCreate.propTypes = {
	accountUrl: PropTypes.string.isRequired,
	childOnboardingUrl: PropTypes.string.isRequired,
	conditionsUrl: PropTypes.string.isRequired,
	currencies: PropTypes.arrayOf(PropTypes.shape({
		code: PropTypes.string.isRequired,
		codeShort: PropTypes.string.isRequired,
		codeShortBefore: PropTypes.bool.isRequired,
	}).isRequired),
	header: PropTypes.bool,
	label: PropTypes.string,
	noActivePortfolio: PropTypes.bool,
	parentOnboardingUrl: PropTypes.string.isRequired,
	portfolios: PropTypes.shape({
		active: PropTypes.arrayOf(
			PropTypes.shape({
				categoryName: PropTypes.string.isRequired,
				child: PropTypes.shape({
					id: PropTypes.number.isRequired,
				}),
			}).isRequired,
		).isRequired,
	}),
	reloadDashboard: PropTypes.func,
	reloadPortfolios: PropTypes.func,
	setScrollDashboard: PropTypes.func,
	signNewAgreementUrl: PropTypes.string.isRequired,
};

PortfolioCreate.defaultProps = {
	currencies: null,
	header: false,
	label: null,
	noActivePortfolio: false,
	portfolios: null,
	reloadDashboard: null,
	reloadPortfolios: null,
	setScrollDashboard: null,
};
