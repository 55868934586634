import getUserAge from './getUserAge';

export const NUMBER_PATTERN = /^[0-9]+$/i;
export const NUMBER_WHITESPACE_PATTERN = /^[0-9\s]+$/i;
export const POSTAL_CODE_PATTERN = /^[0-9]{3}\s?[0-9]{2}$/i;
export const EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const CODE_PATTERN = /^[A-Z0-9]{6}$/i;
export const BANK_ACCOUNT_IBAN_PATTERN = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{8,30}$/i;
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MIN_SCORE = 3;
export const PASSWORD_SCORE_PATTERNS = [/\p{Ll}/u, /\p{Lu}/u, /\p{N}/u];
export const STREET_NAME_PATTERN = /^[A-ZÀ-ž\s-]+$/i;
export const FULL_PHONE_PATTERN = /^([+|0]\d{3})?\d{9}$/;
export const PHONE_NUMBER_FIXED_LENGTH_PREFIXES = ['+420', '+421', '+48'];
export const PHONE_NUMBER_FIXED_PATTERN = /^\d{9}$/;
export const PHONE_NUMBER_WARNING_PATTERN = /^\d{9}\d?$/;
export const PERSONAL_IDENTIFICATION_NUMBER_FORMAT_PATTERN = /^(\d\d)(\d\d)(\d\d)(\d\d\d)(\d?)$/;
export const PORTFOLIO_NAME_PATTERN = /^[^\s].*$/i;
export const PL_PERSONAL_IDENTIFICATION_NUMBER_FORMAT_PATTERN = /^[0-9]{11}$/;
export const PL_POSTAL_CODE_PATTERN = /^[0-9]{2}[\s|-]?[0-9]{3}$/i;
export const PLACE_OF_BIRTH_PATTERN = /^[A-ZÀ-ž0-9\s-]+$/i;
export const DAY_PATTERN = /^(0?[1-9]|[12][0-9]|3[01])$/;
export const MONTH_PATTERN = /^(0?[1-9]|1[0-2])$/;
export const YEAR_PATTERN = /^(19\d{2}|20\d{2})$/;
export const ALPHA_NUMERIC_PATTERN = /^[A-Z0-9]+$/i;

export function validateEmail(email) {
	return EMAIL_PATTERN.test(email);
}

export function validateNumber(number, whitespace = false) {
	if (whitespace) {
		return NUMBER_WHITESPACE_PATTERN.test(number);
	}
	return NUMBER_PATTERN.test(number);
}

export function validateAlphaNumeric(value) {
	return ALPHA_NUMERIC_PATTERN.test(value);
}

export function validatePostalCode(code, country) {
	if (country === 'PL') {
		return PL_POSTAL_CODE_PATTERN.test(code);
	}
	return POSTAL_CODE_PATTERN.test(code);
}

export function validateBankAccountIBAN(bankAccountIBANNumber) {
	return BANK_ACCOUNT_IBAN_PATTERN.test(bankAccountIBANNumber);
}

export function checkBusinessId(businessId, country) {
	const length = country === 'PL' ? 9 : 8;
	if (!validateNumber(businessId) || businessId.length !== length) {
		return false;
	}

	if (country === 'PL') {
		return true;
	}

	let checkSum = 0;
	for (let i = 0; i < 7; i += 1) {
		checkSum += +businessId[i] * (8 - i);
	}

	return +businessId[7] === (11 - (checkSum % 11)) % 10;
}

export function checkStreetName(streetName, country) {
	return country !== 'PL' || STREET_NAME_PATTERN.test(streetName);
}

export function checkStreetNumber(streetNumber, country) {
	return country !== 'PL' || NUMBER_PATTERN.test(streetNumber);
}

export function checkPlaceOfBirth(placeOfBirth) {
	return PLACE_OF_BIRTH_PATTERN.test(placeOfBirth);
}

export function checkPersonalIdentificationNumber(personalIdentificationNumber, country = null) {
	if (country === 'PL') {
		return PL_PERSONAL_IDENTIFICATION_NUMBER_FORMAT_PATTERN.test(personalIdentificationNumber);
	}
	const match = PERSONAL_IDENTIFICATION_NUMBER_FORMAT_PATTERN.exec(personalIdentificationNumber);
	if (match === null) {
		return false;
	}

	let [, year, month, day] = match;
	const ext = match[4];
	const checkDigit = match[5];

	// Check last digit
	if (checkDigit !== '') {
		let modulo = +`${year}${month}${day}${ext}` % 11;

		if (modulo === 10) {
			modulo = 0;
		}

		if (modulo !== +checkDigit) {
			return false;
		}
	}

	year = +year;
	month = +month;
	day = +day;

	// Add century
	year += (year < 54 ? 1900 : 1800) + (checkDigit === '' ? 0 : 100);

	// Month can have 20, 50 or 70 added
	if (month > 70 && year > 2003) {
		month -= 70;
	} else if (month > 50) {
		month -= 50;
	} else if (month > 20 && year > 2003) {
		month -= 20;
	}

	const date = new Date(year, month - 1, day);

	return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
}

export function checkIncomeExpense(value, country) {
	return NUMBER_PATTERN.test(value) && +value >= ({ CZ: 1000, PL: 200, SK: 50 }[country] ?? 0);
}

export function validateCodePattern(code) {
	return CODE_PATTERN.test(code);
}

export function validatePassword(password) {
	if (password.length < PASSWORD_MIN_LENGTH) {
		return false;
	}

	let score = 0;
	PASSWORD_SCORE_PATTERNS.forEach((pattern) => {
		if (pattern.test(password)) {
			score += 1;
		}
	});

	return score >= PASSWORD_MIN_SCORE;
}

export function validateFullPhone(number) {
	return FULL_PHONE_PATTERN.test(number);
}

export function validatePhone(prefix, number) {
	return !PHONE_NUMBER_FIXED_LENGTH_PREFIXES.includes(prefix)
		|| PHONE_NUMBER_FIXED_PATTERN.test(number);
}

export function validatePhoneWarning(prefix, number) {
	return PHONE_NUMBER_FIXED_LENGTH_PREFIXES.includes(prefix)
		|| PHONE_NUMBER_WARNING_PATTERN.test(number);
}

export function validateDay(input) {
	return DAY_PATTERN.test(input);
}

export function validateMonth(input) {
	return MONTH_PATTERN.test(input);
}

export function validateYear(input) {
	return YEAR_PATTERN.test(input);
}

export function validateBirthDate(values, initialErrors = null) {
	const errors = initialErrors ?? {};

	if (!validateNumber(values.birthDateDay)) {
		errors.birthDateDay = 'forms.fields.birthDate.nonNumeric';
	} else if (!validateDay(values.birthDateDay)) {
		errors.birthDateDay = 'forms.fields.birthDate.day.invalid';
	}

	if (!validateNumber(values.birthDateMonth)) {
		errors.birthDateMonth = 'forms.fields.birthDate.nonNumeric';
	} else if (!validateMonth(values.birthDateMonth)) {
		errors.birthDateMonth = 'forms.fields.birthDate.month.invalid';
	}

	if (!validateNumber(values.birthDateYear)) {
		errors.birthDateYear = 'forms.fields.birthDate.nonNumeric';
	} else if (!validateYear(values.birthDateYear)) {
		errors.birthDateYear = 'forms.fields.birthDate.year.invalid';
	}

	return errors;
}

export function validateBankAccount(values, country, initialErrors = null) {
	const errors = initialErrors ?? {};

	if (values.bankAccountType === 'iban') {
		if (!values.bankAccountIBAN) {
			errors.bankAccountIBAN = 'forms.fields.bankAccountIBAN.error';
		} else if (!validateBankAccountIBAN(values.bankAccountIBAN)) {
			errors.bankAccountIBAN = 'forms.fields.bankAccountIBAN.invalid';
		}
	} else {
		if (country !== 'PL' && values.bankPrefix && !validateNumber(values.bankPrefix)) {
			errors.bankPrefix = 'forms.fields.bankAccount.nonNumeric';
		}

		if (!values.bankAccount) {
			errors.bankAccount = 'forms.fields.bankAccount.errorAccount';
		} else if (!validateNumber(values.bankAccount, country === 'PL')) {
			errors.bankAccount = 'forms.fields.bankAccount.nonNumeric';
		} else if (country === 'PL' && values.bankAccount.length !== 32) {
			errors.bankAccount = 'forms.fields.bankAccount.length';
		}

		if (country !== 'PL') {
			if (!values.bankCode) {
				errors.bankCode = 'forms.fields.bankAccount.errorCode';
			} else if (!validateNumber(values.bankCode)) {
				errors.bankCode = 'forms.fields.bankAccount.nonNumeric';
			}
		}
	}

	return errors;
}

export function validatePasswordField(values, initialErrors = null) {
	const errors = initialErrors ?? {};

	if (!values.password) {
		errors.password = 'forms.fields.password.error';
	} else if (!validatePassword(values.password)) {
		errors.password = 'forms.fields.password.invalid';
	}

	return errors;
}

export function validateRegistration(values) {
	const errors = {};

	if (!values.firstName) {
		errors.firstName = 'forms.fields.firstName.error';
	}

	if (!values.lastName) {
		errors.lastName = 'forms.fields.lastName.error';
	}

	if (!values.email) {
		errors.email = 'forms.fields.email.error';
	} else if (!validateEmail(values.email)) {
		errors.email = 'forms.fields.email.invalid';
	}

	validatePasswordField(values, errors);

	if (!values.passwordConfirm) {
		errors.passwordConfirm = 'forms.fields.passwordConfirm.error';
	} else if (values.password !== values.passwordConfirm) {
		errors.passwordConfirm = 'forms.fields.passwordConfirm.invalid';
	}

	if (!values.phoneNumber) {
		errors.phoneNumber = 'forms.fields.phoneNumber.error';
	} else if (!validatePhone(values.phonePrefix, values.phoneNumber)) {
		errors.phoneNumber = 'forms.fields.phoneNumber.invalid';
	}

	if (values.hasReferenceCode && !values.referenceCode) {
		errors.referenceCode = 'forms.fields.referenceCode.error';
	}

	if (!values.personalInfoAgreed) {
		errors.personalInfoAgreed = 'forms.fields.personalInfoAgreed.error';
	}

	if (!values.conditionsAgreed) {
		errors.conditionsAgreed = 'forms.fields.conditionsAgreed.error';
	}

	return errors;
}

export function validateLogin(values) {
	const errors = {};

	if (!values.email) {
		errors.email = 'forms.fields.email.error';
	} else if (!validateEmail(values.email)) {
		errors.email = 'forms.fields.email.invalid';
	}

	if (!values.password) {
		errors.password = 'forms.fields.password.error';
	}

	return errors;
}

export function validateForgotPassword(values) {
	const errors = {};

	if (!values.email) {
		errors.email = 'forms.fields.email.error';
	} else if (!validateEmail(values.email)) {
		errors.email = 'forms.fields.email.invalid';
	}

	return errors;
}

export function validateResetPassword(values) {
	const errors = {};

	validatePasswordField(values, errors);

	if (!values.passwordConfirm) {
		errors.passwordConfirm = 'forms.fields.passwordConfirm.error';
	} else if (values.password !== values.passwordConfirm) {
		errors.passwordConfirm = 'forms.fields.passwordConfirm.invalid';
	}

	return errors;
}

export function validateChangePassword(values) {
	const errors = {};

	if (!values.currentPassword) {
		errors.currentPassword = 'forms.fields.oldPassword.error';
	}

	if (!values.newPassword) {
		errors.newPassword = 'forms.fields.newPassword.error';
	} else if (!validatePassword(values.newPassword)) {
		errors.newPassword = 'forms.fields.password.invalid';
	}

	if (!values.checkNewPassword) {
		errors.checkNewPassword = 'forms.fields.passwordConfirm.error';
	} else if (values.newPassword !== values.checkNewPassword) {
		errors.checkNewPassword = 'forms.fields.passwordConfirm.invalid';
	}

	return errors;
}

export function validateContactForm(values) {
	const errors = {};

	if (!values.fullName) {
		errors.fullName = 'forms.fields.fullName.error';
	}

	if (!values.phone) {
		errors.phone = 'forms.fields.phone.error';
	} else if (!validateFullPhone(values.phone)) {
		errors.phone = 'forms.fields.phone.invalid';
	}

	if (!values.email) {
		errors.email = 'forms.fields.email.error';
	} else if (!validateEmail(values.email)) {
		errors.email = 'forms.fields.email.invalid';
	}

	if (!values.personalInfoAgreed) {
		errors.personalInfoAgreed = 'forms.fields.personalInfoAgreed.error';
	}

	return errors;
}

export function validateContactUs(values) {
	const errors = {};

	if (!values.text) {
		errors.text = 'contactUs.text.error';
	}

	if (!values.name) {
		errors.name = 'contactUs.name.error';
	}

	if (!values.email) {
		errors.email = 'contactUs.email.error';
	} else if (!validateEmail(values.email)) {
		errors.email = 'forms.fields.email.invalid';
	}

	if (!values.conditions) {
		errors.conditions = 'contactUs.conditions.error';
	}

	return errors;
}

export function validateInvestingPortfolioIncome(values, min, max) {
	const errors = {};

	if (!values.income) {
		errors.income = 'investingPortfolio.income.error';
	} else if (!validateNumber(values.income)) {
		errors.income = 'investingPortfolio.income.nonNumericError';
	} else if (values.income < min) {
		errors.income = 'investingPortfolio.income.minError';
	} else if (values.income > max) {
		errors.income = 'investingPortfolio.income.maxError';
	}

	return errors;
}

export function validatePersonalData(values, country, isBirthDate, category, birthDate) {
	const errors = {};

	if (values.postalCode && !validatePostalCode(values.postalCode, country)) {
		errors.postalCode = 'forms.fields.postalCode.nonNumeric';
	}

	if (values.contactAddressPostalCode && !validatePostalCode(values.contactAddressPostalCode, country)) {
		errors.contactAddressPostalCode = 'forms.fields.postalCode.nonNumeric';
	}

	if (isBirthDate) {
		validateBirthDate(values, errors);
	}

	if (!values.nationality) {
		errors.nationality = 'forms.fields.nationality.error';
	}

	validateBankAccount(values, country, errors);

	if (!values.confirm) {
		errors.confirm = 'onboarding.steps.personalData.form.confirm.error';
	}

	if (values.numberISIC && (!validateAlphaNumeric(values.numberISIC) || values.numberISIC.length !== 14)) {
		errors.numberISIC = 'forms.fields.numberISIC.format';
	}

	if (birthDate && category === 'student' && !values.hasStudentConfirmation && getUserAge(birthDate) < 26) {
		errors.hasStudentConfirmation = 'onboarding.steps.personalData.student.error';
	}

	return errors;
}

export function validateStudentConfirmationUpload(values) {
	const errors = {};

	if (values.numberISIC && (!validateAlphaNumeric(values.numberISIC) || values.numberISIC.length !== 14)) {
		errors.numberISIC = 'forms.fields.numberISIC.format';
	}

	return errors;
}

export function validateReadAgreement(values) {
	const errors = {};

	if (!values.readAgreement) {
		errors.readAgreement = 'onboarding.steps.agreement.formAgreement.readAgreement.required';
	}

	return errors;
}

export function validateCode(values) {
	const errors = {};

	if (!values.code) {
		errors.code = 'onboarding.steps.agreement.formCode.code.error';
	} else if (values.code && !validateCodePattern(values.code)) {
		errors.code = 'onboarding.steps.agreement.formCode.code.invalid';
	}

	return errors;
}

export function validateOnboardingPayment(values) {
	const errors = {};

	if (!values.sentMoney) {
		errors.sentMoney = 'onboarding.steps.payment.sentMoney.error';
	}

	return errors;
}

export function validateEmailShare(values) {
	const errors = {};

	if (!values.email) {
		errors.email = 'referrals.form.email.error';
	} else if (!validateEmail(values.email)) {
		errors.email = 'forms.fields.email.invalid';
	}

	if (!values.message) {
		errors.message = 'referrals.form.message.error';
	}

	return errors;
}

export function validateSurvey(values) {
	const errors = {};

	const isOnlyChoice = (value) => (
		Array.isArray(values.choices) ? values.choices.every((val) => val === value) : values.choices === value
	);

	if (
		values.choices === null
		|| (Array.isArray(values.choices) && values.choices.length === 0)
		|| (isOnlyChoice('0') && values.text === '')
	) {
		errors.survey = true;
	}

	return errors;
}

export function validateSavingsCalculator(values, isTypeFondee) {
	const errors = {};

	if (!values.deposit && values.deposit !== 0) {
		errors.deposit = 'campaign.calculator.fields.deposit.error';
	} else if (values.deposit < 1000 || values.deposit > 10000000) {
		errors.deposit = 'campaign.calculator.fields.deposit.errorRange';
	}

	if (!values.accountInterestRate && values.accountInterestRate !== 0) {
		errors.accountInterestRate = 'campaign.calculator.fields.interestRate.account.error';
	} else if (values.accountInterestRate < 0 || values.accountInterestRate > 10) {
		errors.accountInterestRate = 'campaign.calculator.fields.interestRate.account.errorRange';
	}

	if (values.fondeeInterestRate !== 8) {
		errors.fondeeInterestRate = 'campaign.calculator.fields.interestRate.fondee.error';
	}

	if (!values.inflationRate && values.accountInterestRate !== 0) {
		errors.inflationRate = 'campaign.calculator.fields.inflationRate.error';
	} else if (values.inflationRate < 1) {
		errors.inflationRate = 'campaign.calculator.fields.inflationRate.errorRangeMin';
	} else if (values.inflationRate > 15) {
		errors.inflationRate = 'campaign.calculator.fields.inflationRate.errorRangeMax';
	}

	if (!values.years && values.years !== 0) {
		errors.years = `campaign.calculator.fields.years.${isTypeFondee ? 'fondee' : 'account'}.error`;
	} else if (!Number.isInteger(values.years)) {
		errors.years = `campaign.calculator.fields.years.${isTypeFondee ? 'fondee' : 'account'}.errorFormat`;
	} else if (values.years < 1 || values.years > 50) {
		errors.years = `campaign.calculator.fields.years.${isTypeFondee ? 'fondee' : 'account'}.errorRange`;
	}

	return errors;
}

export function validatePhoneChange(values) {
	const errors = {};

	if (!values.phoneNumber) {
		errors.phoneNumber = 'forms.fields.phoneNumber.error';
	} else if (!validatePhone(values.phonePrefix, values.phoneNumber)) {
		errors.phoneNumber = 'forms.fields.phoneNumber.invalid';
	}

	return errors;
}

export function validatePortfolioName(name) {
	return PORTFOLIO_NAME_PATTERN.test(name);
}

export function validatePortfolioCreate(values) {
	const errors = {};

	if (!values.portfolioName) {
		errors.portfolioName = 'portfolio.new.form.name.empty';
	} else if (!validatePortfolioName(values.portfolioName)) {
		errors.portfolioName = 'portfolio.new.form.name.error';
	}

	return errors;
}

export function validatePersonalInformation(values, country, phoneRequired) {
	const errors = {};

	if (!values.firstName) {
		errors.firstName = 'forms.fields.firstName.error';
	}

	if (!values.lastName) {
		errors.lastName = 'forms.fields.lastName.error';
	}

	if (!values.streetName) {
		errors.streetName = 'forms.fields.streetName.error';
	}

	if (!values.streetNumber) {
		errors.streetNumber = 'forms.fields.streetNumber.error';
	}

	if (!values.city) {
		errors.city = 'forms.fields.city.error';
	}

	if (values.postalCode && !validatePostalCode(values.postalCode, country)) {
		errors.postalCode = 'forms.fields.postalCode.nonNumeric';
	}

	if (!values.contactAddressStreetName) {
		errors.contactAddressStreetName = 'forms.fields.streetName.error';
	}

	if (!values.contactAddressStreetNumber) {
		errors.contactAddressStreetNumber = 'forms.fields.streetNumber.error';
	}

	if (!values.contactAddressCity) {
		errors.contactAddressCity = 'forms.fields.city.error';
	}

	if (values.contactAddressPostalCode && !validatePostalCode(values.contactAddressPostalCode, country)) {
		errors.contactAddressPostalCode = 'forms.fields.postalCode.nonNumeric';
	}

	if (phoneRequired && !values.phoneNumber) {
		errors.phoneNumber = 'forms.fields.phoneNumber.error';
	} else if (values.phoneNumber && !validatePhone(values.phonePrefix, values.phoneNumber)) {
		errors.phoneNumber = 'forms.fields.phoneNumber.invalid';
	}

	return errors;
}

export function validateChildInformation(values) {
	const errors = {};

	if (!values.email) {
		errors.email = 'forms.fields.email.error';
	} else if (!validateEmail(values.email)) {
		errors.email = 'forms.fields.email.invalid';
	}

	if (!values.phoneNumber) {
		errors.phoneNumber = 'forms.fields.phoneNumber.error';
	} else if (!validatePhone(values.phonePrefix, values.phoneNumber)) {
		errors.phoneNumber = 'forms.fields.phoneNumber.invalid';
	}

	return errors;
}

export function validateChildData(values, country) {
	const errors = {};

	validateBirthDate(values, errors);

	if (values.email !== '' && !validateEmail(values.email)) {
		errors.email = 'forms.fields.email.invalid';
	}

	if (values.phoneNumber !== '' && !validatePhone(values.phonePrefix, values.phoneNumber)) {
		errors.phoneNumber = 'forms.fields.phoneNumber.invalid';
	}

	if (values.bankAccount !== '' || values.bankAccountIBAN !== '' || values.bankCode !== '' || values.bankPrefix !== '') {
		validateBankAccount(values, country, errors);
	}

	if (!values.personalInfoAgreed) {
		errors.personalInfoAgreed = 'onboarding.steps.personalData.form.confirm.error';
	}

	if (!values.conditionsAgreed) {
		errors.conditionsAgreed = 'onboarding.steps.personalData.form.confirm.error';
	}

	return errors;
}

export function validateEmployerCreate(values, country) {
	const errors = {};

	if (values.businessId !== '' && !checkBusinessId(values.businessId, country)) {
		errors.businessId = 'forms.fields.businessId.warning';
	}

	return errors;
}

export function validateChildEmailChange(values) {
	const errors = {};

	if (!values.email) {
		errors.email = 'forms.fields.email.error';
	}

	if (values.email !== '' && !validateEmail(values.email)) {
		errors.email = 'forms.fields.email.invalid';
	}

	return errors;
}
