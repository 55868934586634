import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useCountry } from '../../../contexts/LocaleContext';
import { filterChild, useUserContext } from '../../../contexts/UserContext';
import { setSessionPortfolioId } from '../../../utils/sessionPortfolio';
import useFormatNumber from '../../../utils/useFormatNumber';
import {
	Col,
	Container,
	Section,
	Row,
} from '../../layout';
import {
	Badge,
	Button,
	ButtonLink,
	TopUpModal,
} from '../../ui';
import DashboardFilter from '../DashboardFilter';
import PortfolioTableGrowth from './PortfolioTableGrowth';

import styles from './PortfolioTable.module.scss';

export default function PortfolioTable({
	dashboardData,
	portfolioUrl,
	setPeriod,
	setScrollDashboard,
	isProxy,
}) {
	const [t] = useTranslation();
	const country = useCountry();
	const formatNumber = useFormatNumber();
	const [user] = useUserContext();
	const formatCurrency = (value, currency, fractionDigits = null) => formatNumber(value, {
		style: 'currency',
		currency: currency?.code,
		fractionDigits,
	});

	return (
		<Section
			condensed
			id="portfolioTable"
		>
			<Container>
				<Row>
					<Col lg={4}>
						<h2 className={styles.title}>
							{t('portfolioTable.title', { count: dashboardData.portfolios.length })}
						</h2>
					</Col>
					<Col lg={4}>
						<DashboardFilter
							dashboardData={dashboardData}
							setPeriod={setPeriod}
							setScrollDashboard={setScrollDashboard}
							portfolios
						/>
					</Col>
				</Row>
				<div className={styles.tableWrap}>
					<table className={styles.table}>
						<thead>
							<tr className={styles.highlight}>
								<th>
									{t('portfolioTable.table.columns.name')}
								</th>
								<th className={styles.right}>
									{t('portfolioTable.table.columns.value')}
								</th>
								<th className={styles.right}>
									{t('portfolioTable.table.columns.appreciation')}
								</th>
								{!isProxy && (
									<th className={styles.hideMobile}>
										{' '}
									</th>
								)}
							</tr>
						</thead>
						<tbody>
							{dashboardData.portfolios.map((portfolio) => (
								<tr key={portfolio.id}>
									<td className={!isProxy ? styles.hideMobile : ''}>
										{portfolio.faceImageFile && (
											<img
												src={`${process.env.PUBLIC_URL}/images/faces/${portfolio.faceImageFile}`}
												alt={portfolio.name ?? ''}
												width={32}
												height={32}
											/>
										)}
										{filterChild(portfolio.child, user) && (
											<span className={styles.badgeWrap}>
												<Badge label={portfolio.child.firstName} size="smaller" variant="orange" />
											</span>
										)}
										{portfolio.categoryName === 'pension' && (
											<span className={styles.badgeWrap}>
												<Badge label={t('productSelect.pension')} size="smaller" variant="wine" />
												{country === 'CZ' && <Badge label="DIP" size="smaller" variant="wine" />}
											</span>
										)}
										{portfolio.categoryName === 'student' && (
											<span className={styles.badgeWrap}>
												<Badge label={t('productSelect.studentBadge')} size="tiny" variant="blue" />
											</span>
										)}
										<span className={styles.name}>
											{portfolio.name ?? ''}
										</span>
									</td>
									{!isProxy && (
										<td className={styles.hideDesktop}>
											<a
												className={styles.link}
												onClick={() => setSessionPortfolioId(portfolio.id)}
												href={portfolioUrl}
											>
												{portfolio.faceImageFile && (
													<img
														src={`${process.env.PUBLIC_URL}/images/faces/${portfolio.faceImageFile}`}
														alt={portfolio.name ?? ''}
														width={32}
														height={32}
													/>
												)}
												{filterChild(portfolio.child, user) && (
													<span className={styles.badgeWrap}>
														<Badge label={portfolio.child.firstName} size="tiny" variant="orange" />
													</span>
												)}
												{portfolio.categoryName === 'pension' && (
													<span className={styles.badgeWrap}>
														<Badge label={t('productSelect.pension')} size="tiny" variant="wine" />
														{/* DIP badge does not fit */}
													</span>
												)}
												<span className={styles.name}>
													{portfolio.name ?? ''}
												</span>
											</a>
										</td>
									)}
									<td className={styles.right}>
										{formatCurrency(portfolio.totalValue, portfolio.currency)}
									</td>
									<td className={styles.right}>
										<PortfolioTableGrowth
											value={portfolio.netGrowthRatio}
											ratio
										/>
									</td>
									{!isProxy && (
										<td className={`${styles.right} ${styles.hideMobile}`.trim()}>
											<TopUpModal
												dashboard
												defaultPortfolioId={portfolio.id}
												portfolios={dashboardData.portfolios}
												small
											/>
											{user.client_zone_locked ? (
												<Button
													disabled
													isNarrow
													isSmall
													isFlat
													label={t('portfolioTable.table.more')}
													tooltip={t('clientCheck.disabledTooltip')}
													outline
												/>
											) : (
												<ButtonLink
													isNarrow
													isSmall
													isFlat
													label={t('portfolioTable.table.more')}
													onClick={() => setSessionPortfolioId(portfolio.id)}
													outline
													to={portfolioUrl}
												/>
											)}
										</td>
									)}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</Container>
		</Section>
	);
}

PortfolioTable.propTypes = {
	dashboardData: PropTypes.shape({
		maxPeriod: PropTypes.string.isRequired,
		period: PropTypes.string.isRequired,
		portfolios: PropTypes.arrayOf(PropTypes.shape({
			child: PropTypes.shape({
				firstName: PropTypes.string.isRequired,
				id: PropTypes.number.isRequired,
			}),
			currency: PropTypes.shape({
				code: PropTypes.string.isRequired,
				codeShort: PropTypes.string.isRequired,
				codeShortBefore: PropTypes.bool.isRequired,
			}).isRequired,
			faceImageFile: PropTypes.string,
			id: PropTypes.number.isRequired,
			name: PropTypes.string,
			netGrowthRatio: PropTypes.number,
			totalValue: PropTypes.number,
			categoryName: PropTypes.string,
		}).isRequired).isRequired,
	}).isRequired,
	portfolioUrl: PropTypes.string.isRequired,
	setPeriod: PropTypes.func.isRequired,
	setScrollDashboard: PropTypes.func,
	isProxy: PropTypes.bool,
};

PortfolioTable.defaultProps = {
	isProxy: false,
	setScrollDashboard: null,
};
