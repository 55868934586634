import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

import { FileField, TextField } from '../../../../components/forms';
import { Alert, Button, Toggle } from '../../../../components/ui';
import { isicCardVerification, studentCertificationDocumentUpload } from '../../../../utils/api';
import useRequestAuth from '../../../../utils/useRequestAuth';
import { validateStudentConfirmationUpload } from '../../../../utils/validators';

import styles from './StudentConfirmationUpload.module.scss';

export default function StudentConfirmationUpload({
	reloadUser,
}) {
	const [t] = useTranslation();
	const [manualCheck, setManualCheck] = useState(false);
	const [errorCode, setErrorCode] = useState(false);
	const [errorManual, setErrorManual] = useState(false);
	const [success, setSuccess] = useState(false);
	const isicCardVerificationAuth = useRequestAuth(isicCardVerification);
	const studentCertificationDocumentUploadAuth = useRequestAuth(studentCertificationDocumentUpload);

	if (success) {
		return (
			<Alert type="success">
				{t('studentConfirmation.success')}
			</Alert>
		);
	}

	return (
		<div>
			<Toggle
				labelFirst={t('studentConfirmation.toggle.ISIC')}
				labelSecond={t('studentConfirmation.toggle.confirmation')}
				setToggle={setManualCheck}
				toggle={manualCheck}
			/>
			{manualCheck ? (
				<Formik
					initialValues={{
						fileField: undefined,
					}}
					onSubmit={async (values, { setErrors }) => {
						const formData = new FormData();
						const fileName = `${values.fileField.name.replace(/(.)\.[^/.]*$/, '$1')}.${values.fileField.type.split('/')[1]}`;
						formData.append('studentCertification', values.fileField, fileName);
						setErrorManual(false);

						try {
							await studentCertificationDocumentUploadAuth(
								formData,
							);
						} catch (e) {
							const fieldErrors = {};

							const hasFieldErrors = Object.keys(fieldErrors).length > 0;
							if (hasFieldErrors) {
								setErrors(fieldErrors);
							} else {
								const formError = 'forms.error';
								setErrorManual(formError);
							}
						}
						setSuccess(true);
						setTimeout(() => {
							reloadUser();
						}, 5000);
					}}
				>
					{({
						handleSubmit,
						isSubmitting,
						setFieldValue,
					}) => (
						<form className={styles.formWrap} onSubmit={handleSubmit}>
							{errorManual && (
								<Alert type="danger">
									{t('forms.error')}
								</Alert>
							)}
							<FileField
								accept={['.jpeg', '.jpg', '.png', '.pdf']}
								id="studentConfirmation-fileField"
								name="studentConfirmation-fileField"
								maxFileSize={4096}
								label={t('studentConfirmation.upload.file')}
								setFieldValue={setFieldValue}
								onChange={(e) => {
									setFieldValue('fileField', e.currentTarget.files[0]);
								}}
								isLabelHidden
								required
							/>
							<div className={styles.infoBox}>
								<span className={styles.infoIcon}>
									<svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g clipPath="url(#clip0_4993_6316)">
											<path
												d="M9.16602 5.83366H10.8327V7.50033H9.16602V5.83366ZM9.16602 9.16699H10.8327V14.167H9.16602V9.16699ZM9.99935 1.66699C5.39935 1.66699 1.66602 5.40033 1.66602 10.0003C1.66602 14.6003 5.39935 18.3337 9.99935 18.3337C14.5993 18.3337 18.3327 14.6003 18.3327 10.0003C18.3327 5.40033 14.5993 1.66699 9.99935 1.66699ZM9.99935 16.667C6.32435 16.667 3.33268 13.6753 3.33268 10.0003C3.33268 6.32533 6.32435 3.33366 9.99935 3.33366C13.6743 3.33366 16.666 6.32533 16.666 10.0003C16.666 13.6753 13.6743 16.667 9.99935 16.667Z"
												fill="#47202A"
											/>
										</g>
										<defs>
											<clipPath id="clip0_4993_6316">
												<rect width="20" height="20" fill="white" />
											</clipPath>
										</defs>
									</svg>
								</span>
								<div className={styles.infoList}>
									<ul>
										<Trans i18nKey="studentConfirmation.upload.list.formats">
											<li className={styles.infoListTitle}>{t('studentConfirmation.upload.list.formats')}</li>
											<li className={styles.infoListItem}>{t('studentConfirmation.upload.list.formats')}</li>
										</Trans>
									</ul>
								</div>
								<div className={styles.infoList}>
									<ul>
										<Trans i18nKey="studentConfirmation.upload.list.size">
											<li className={styles.infoListTitle}>{t('studentConfirmation.upload.list.size')}</li>
											<li className={styles.infoListItem}>{t('studentConfirmation.upload.list.size')}</li>
										</Trans>
									</ul>
								</div>
							</div>
							<Button
								disabled={isSubmitting}
								isSubmit
								label={t('studentConfirmation.upload.confirm')}
							/>
						</form>
					)}
				</Formik>
			) : (
				<Formik
					initialValues={{
						numberISIC: '',
					}}
					onSubmit={async (values, { setErrors }) => {
						setErrorCode(false);

						try {
							await isicCardVerificationAuth(
								values.numberISIC,
							);
						} catch (e) {
							const fieldErrors = {};

							const hasFieldErrors = Object.keys(fieldErrors).length > 0;
							if (hasFieldErrors) {
								setErrors(fieldErrors);
							} else {
								const formError = 'forms.error';
								setErrorCode(formError);
							}
						}
						setSuccess(true);
						setTimeout(() => {
							reloadUser();
						}, 5000);
					}}
					validate={(values) => validateStudentConfirmationUpload(values)}
				>
					{({
						errors,
						handleBlur,
						handleChange,
						handleSubmit,
						isSubmitting,
						touched,
						values,
					}) => (
						<form className={styles.formWrap} onSubmit={handleSubmit}>
							{errorCode && (
								<Alert type="danger">
									{t('forms.error')}
								</Alert>
							)}
							<TextField
								error={
									errors.numberISIC
											&& touched.numberISIC
											&& t(errors.numberISIC)
								}
								id="numberISIC"
								label={t('studentConfirmation.manual.code')}
								name="numberISIC"
								onBlur={handleBlur}
								onChange={handleChange}
								type="text"
								value={values.numberISIC}
								required
							/>

							<Button
								disabled={isSubmitting}
								isSubmit
								label={t('studentConfirmation.manual.confirm')}
							/>
						</form>
					)}
				</Formik>
			)}
		</div>
	);
}

StudentConfirmationUpload.propTypes = {
	reloadUser: PropTypes.func.isRequired,
};
