import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { RadioList, SelectField, TextField } from '../../../components/forms';
import {
	Alert,
	Badge,
	Button,
	Loader,
	Modal,
} from '../../../components/ui';
import { useCountry } from '../../../contexts/LocaleContext';
import { filterChild, useUserContext } from '../../../contexts/UserContext';
import { closeAccount } from '../../../utils/api';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import useRequestAuth from '../../../utils/useRequestAuth';

import styles from './ChildClosure.module.scss';

export default function ChildClosure({
	childId,
	currencies,
	inline,
	portfolioUrl,
	portfolios,
	reloadChildrenList,
	reloadUser,
	userCurrency,
}) {
	const [t] = useTranslation();
	const country = useCountry();
	const [user] = useUserContext();
	const trackGTMEvent = useTrackGTMEvent();
	const [confirmCancel, setConfirmCancel] = useState(false);
	const [cancelError, setCancelError] = useState(false);
	const [cancelHas2fa, setCancelHas2fa] = useState(false);
	const [defaultPortfolioSelect, setDefaultPortfolioSelect] = useState(false);
	const [newDefaultPortfolioId, setNewDefaultPortfolioId] = useState(null);
	const closeAccountAuth = useRequestAuth(closeAccount);

	const loading = currencies === null || userCurrency === null;

	const currencySelect = currencies !== null && currencies.length > 1;
	const currencyDefault = currencies !== null
		? currencies.find(({ code }) => code === userCurrency) ?? currencies[0] ?? null
		: null;
	const currencyDefaultValue = currencyDefault !== null ? currencyDefault.code : null;
	const currencyOptions = currencies !== null ? currencies.map(({ code }) => ({
		label: code,
		value: code,
	})) : null;

	const portfoliosOptions = portfolios.filter(
		({ blocked, child }) => !blocked && child?.id !== childId,
	).map((item) => ({
		label: (
			<>
				<img
					src={`${process.env.PUBLIC_URL}/images/faces/${item.faceImageFile}`}
					alt={item.name}
					width={40}
					height={40}
				/>
				{filterChild(item.child, user) && (
					<span className={styles.badgeWrap}>
						<Badge label={item.child.firstName} size="tiny" variant="orange" />
					</span>
				)}
				{item.categoryName === 'pension' && (
					<span className={styles.badgeWrap}>
						<Badge label={t('productSelect.pension')} size="tiny" variant="wine" />
						{country === 'CZ' && <Badge label="DIP" size="tiny" variant="wine" />}
					</span>
				)}
				{item.categoryName === 'student' && (
					<span className={styles.badgeWrap}>
						<Badge label={t('productSelect.studentBadge')} size="tiny" variant="blue" />
					</span>
				)}
				<span>{item.name}</span>
			</>
		),
		value: item.id,
	}));

	const isDefaultUserPortfolio = portfolios.some(
		({ id, child }) => id === user.deposit_default_portfolio_id && child?.id === childId,
	) && portfoliosOptions.length > 0;

	return (
		<div className={`${styles.root} ${inline && styles.inline}`.trim()}>
			<div className={loading ? styles.loader : ''}>
				{loading ? <Loader /> : (
					<Formik
						initialValues={{
							twoFactorCode: '',
							currency: currencySelect ? currencyDefaultValue : null,
						}}
						onSubmit={async (values, { setErrors }) => {
							const currency = currencySelect ? values.currency : currencyDefaultValue;
							if (currency === null) {
								return;
							}

							let show2fa = false;
							setCancelError(false);
							try {
								await closeAccountAuth(
									cancelHas2fa ? values.twoFactorCode : null,
									currency,
									childId,
									newDefaultPortfolioId,
								);
							} catch (e) {
								const fieldErrors = {};
								const errorMessage = e.responseJson?.message;
								if (typeof errorMessage === 'string' && errorMessage.indexOf('Two factor authentication code needed') !== -1) {
									setCancelHas2fa(true);
									show2fa = true;
								}

								if (typeof errorMessage === 'string' && errorMessage.indexOf('Bad two factor authentication code') !== -1) {
									fieldErrors.twoFactorCode = 'forms.twoFactorAuthentication.error';
								}

								const hasFieldErrors = Object.keys(fieldErrors).length > 0;
								if (hasFieldErrors) {
									setErrors(fieldErrors);
								}
								setCancelError(!hasFieldErrors && !show2fa);
								return;
							}
							trackGTMEvent('interactions', {
								eventCategory: 'interactions',
								eventAction: 'buttonClick',
								eventLabel: 'insert_withdrawal_closure_account',
							});
							reloadChildrenList();
							reloadUser();
							setCancelHas2fa(false);
							setConfirmCancel(false);
						}}
					>
						{({
							errors,
							handleBlur,
							handleChange,
							handleSubmit,
							isSubmitting,
							touched,
							values,
						}) => (
							<form onSubmit={handleSubmit}>
								<Modal
									isVisible={confirmCancel}
									onClose={() => {
										setCancelHas2fa(false);
										setConfirmCancel(false);
									}}
									title={t('account.childClosure.cancel.title')}
								>
									<div className={styles.modal}>
										<Trans i18nKey="account.childClosure.cancel.text">
											<p className={styles.modalText}>
												<Link to={portfolioUrl} />
											</p>
										</Trans>
										{cancelError && (
											<Alert type="danger">
												{t('forms.error')}
											</Alert>
										)}
										{cancelHas2fa && (
											<TextField
												autoFocus={cancelHas2fa}
												autocomplete="one-time-code"
												error={
													errors.twoFactorCode
													&& touched.twoFactorCode
													&& t(errors.twoFactorCode)
												}
												id={`accountChildClosureTwoFactorCode-${childId}`}
												inputMode="numeric"
												label={t('account.withdrawals.cancel.popup.twoFactorAuthentication')}
												name="twoFactorCode"
												onBlur={handleBlur}
												onChange={handleChange}
												pattern="[0-9]*"
												required={cancelHas2fa}
												type="text"
												value={values.twoFactorCode}
											/>
										)}
										{currencySelect && (
											<RadioList
												id={`accountChildClosureCurrency-${childId}`}
												name="currency"
												onChange={handleChange}
												options={currencyOptions}
												value={values.currency}
											/>
										)}
										<Button
											disabled={
												isSubmitting
												|| (currencySelect ? values.currency : currencyDefaultValue) === null
											}
											isSubmit={confirmCancel && !isDefaultUserPortfolio}
											label={t('account.childClosure.cancel.confirm')}
											onClick={(e) => {
												if (isDefaultUserPortfolio) {
													e.preventDefault();
													setDefaultPortfolioSelect(true);
												}
											}}
										/>
									</div>
								</Modal>
								<Modal
									isVisible={defaultPortfolioSelect}
									onClose={() => setDefaultPortfolioSelect(false)}
									title={t('account.withdrawals.cancel.portfolio.defaultPortfolioSelect.title')}
									overflow
								>
									<div className={styles.modal}>
										<p className={styles.modalText}>
											{t('account.withdrawals.cancel.portfolio.defaultPortfolioSelect.text')}
										</p>
										<div className={styles.select}>
											<SelectField
												id="depositDefaultPortfolio"
												label={t('account.depositDefaultPortfolio.label')}
												onChange={(_, value) => setNewDefaultPortfolioId(value)}
												options={portfoliosOptions}
												name="portfolio"
												value={newDefaultPortfolioId}
											/>
										</div>
										<Button
											disabled={isSubmitting}
											isSubmit={isDefaultUserPortfolio}
											label={t('account.withdrawals.cancel.portfolio.defaultPortfolioSelect.button')}
										/>
									</div>
								</Modal>
								<a
									className={styles.cancelLink}
									href="#childClosure"
									onClick={(e) => {
										e.preventDefault();
										setConfirmCancel(true);
									}}
								>
									{t('account.childList.personalInformation.cancelAccount')}
								</a>
							</form>
						)}
					</Formik>
				)}
			</div>
		</div>
	);
}

ChildClosure.propTypes = {
	childId: PropTypes.number.isRequired,
	currencies: PropTypes.arrayOf(PropTypes.shape({
		code: PropTypes.string.isRequired,
		codeShort: PropTypes.string.isRequired,
		codeShortBefore: PropTypes.bool.isRequired,
	}).isRequired),
	inline: PropTypes.bool,
	portfolioUrl: PropTypes.string.isRequired,
	portfolios: PropTypes.arrayOf(PropTypes.shape({
		categoryName: PropTypes.string.isRequired,
		child: PropTypes.shape({
			firstName: PropTypes.string.isRequired,
			id: PropTypes.number.isRequired,
		}),
		faceImageFile: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
		name: PropTypes.string,
	}).isRequired).isRequired,
	reloadChildrenList: PropTypes.func.isRequired,
	reloadUser: PropTypes.func.isRequired,
	userCurrency: PropTypes.string,
};

ChildClosure.defaultProps = {
	currencies: null,
	inline: false,
	userCurrency: null,
};
